import CancelIcon from '@mui/icons-material/Cancel'
import PersonIcon from '@mui/icons-material/Person'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import {IconButton, Paper, Tab, Tabs, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {Reducer, useCallback, useMemo, useReducer, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState,
  CashDeskCustomerFieldsFragment,
  CheckoutOptions,
  DetailClientPropertiesFragment,
  LeadField,
  LeadInputStatus,
  LeadOption,
  PaymentMethodPropertiesFragment,
  PermissionCode,
  TemplateFileType,
  TemplatesQuery
} from '../../../../../__generated__/schema'
import {useDownloadSalesPdf} from '../../../../../hooks/downloadSalesPdf'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useOpenCashDrawer} from '../../../../../hooks/openCashDrawer'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {Theme} from '../../../../../theme'
import {
  CashDrawerOpenLocation,
  CheckoutPaymentMethodsViewMode
} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  getIsPosTerminalAppConnectionValid,
  useShowPosTerminalAppSettingsError
} from '../../../../../utils/getTerminalConfig'
import {safeSum} from '../../../../../utils/money'
import {routeTo} from '../../../../../utils/routes'
import {Blank} from '../../../../visual/Blank'
import {useGetTicketPrintingTemplates} from '../../hooks/getTicketPrintingTemplates'
import {useCurrentCart} from '../CurrentCartContext'
import {
  getPaymentIntentsInputs,
  ILeadForm,
  IPaymentIntentsForm,
  PaymentIntentField
} from '../types'
import {getCustomerParamsForMutation} from '../utils'
import {cartSatisfiesExpectedCheckoutOption} from './cartSatisfiesExpectedCheckoutOption'
import {useCheckoutErrorHandler} from './checkoutErrorHandler'
import {ChildrenOnValidCartCheckoutOptions} from './ChildrenOnValidCartCheckoutOptions'
import {ChildrenOnValidPaymentMethod} from './ChildrenOnValidPaymentMethod'
import {BaseCustomerCard} from './CustomerCard'
import {CustomersDrawer} from './CustomersDrawer'
import {useCheckout, useReserve} from './graphql'
import {GroupIntentStructure} from './GroupIntentStructure'
import {InHouseVoucherIntentStructure} from './InHouseVoucherIntentStructure'
import {InHouseVoucherRowStructure} from './InHouseVoucherRowStructure'
import {
  getIntentsStructureDefaultState,
  IntentsStructureDispatchContext,
  intentsStructureReducer,
  IntentStructureAction,
  IntentStructureActionType
} from './intentsStructure'
import {LeadDetail} from './LeadDetail'
import {PaymentFooter} from './PaymentFooter'
import {ReservationFooter} from './ReservationFooter'
import {TypeIntentStructure} from './TypeIntentStructure'
import {IntentsStructureReducer} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    overflow: 'hidden'
  },
  header: {
    height: 48,
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  scrollableBox: {
    height: '100%',
    overflow: 'auto'
  },
  contentColumn: {
    maxWidth: 'min(100%, 480px)',
    minWidth: 360,
    margin: 'auto',
    padding: theme.spacing(2)
  },
  fulFilledContent: {
    gridRow: '1/3'
  },
  frames: {
    paddingTop: theme.spacing(2)
  }
}))

const isNotUnavailableLeadOptionFilter = (leadOption: LeadOption) =>
  leadOption.inputStatus !== LeadInputStatus.Unavailable

enum CartTabs {
  PAYMENT = 'payment',
  RESERVATION = 'reservation'
}

interface IDraftCartCoreProps {
  className?: string
  effectiveClient: DetailClientPropertiesFragment
  paymentMethods: PaymentMethodPropertiesFragment[]
}

const getTabInitialState = ({
  currentCart,
  hasReservePermission,
  hasCheckoutPermission
}: {
  currentCart: CartPropertiesFragment | null
  hasReservePermission: boolean
  hasCheckoutPermission: boolean
}) => {
  if (hasCheckoutPermission && hasReservePermission) {
    if (
      cartSatisfiesExpectedCheckoutOption(currentCart, CheckoutOptions.Sale)
    ) {
      return CartTabs.PAYMENT
    } else if (
      cartSatisfiesExpectedCheckoutOption(
        currentCart,
        CheckoutOptions.Reservation
      )
    ) {
      return CartTabs.RESERVATION
    }
  }
  return hasCheckoutPermission ? CartTabs.PAYMENT : CartTabs.RESERVATION
}

const getSurplus = (
  price: number,
  paymentIntentsForm: IPaymentIntentsForm
): number =>
  new Decimal(
    safeSum(
      Object.values(paymentIntentsForm).map(
        (intent) => intent[PaymentIntentField.Amount]
      )
    )
  )
    .minus(price)
    .toNumber()

export const DraftCartCore: React.FC<IDraftCartCoreProps> = ({
  className,
  effectiveClient,
  paymentMethods
}: IDraftCartCoreProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {P} = useEnsurePermissions()
  const {currentCartId, currentCart, updateCurrentCart} = useCurrentCart()
  const openCashDrawer = useOpenCashDrawer()
  const checkoutErrorHandler = useCheckoutErrorHandler()
  const hasReservePermission = P([PermissionCode.Reserve])
  const hasCheckoutPermission = P([PermissionCode.Checkout])
  const history = useHistory()
  const addPrintingTemplateDataToHash = useCallback(
    ({
      templateId,
      templateFileType,
      uuid,
      hash
    }: {
      templateId: number
      templateFileType: TemplateFileType
      uuid?: string | null
      hash?: string | null
    }) => {
      history.replace({
        hash:
          uuid && hash
            ? `templateId=${templateId}&templateFileType=${templateFileType}&uuid=${uuid}&hash=${hash}`
            : `templateId=${templateId}&templateFileType=${templateFileType}`
      })
    },
    [history]
  )
  const [tab, setTab] = useState<CartTabs>(
    getTabInitialState({
      currentCart,
      hasReservePermission,
      hasCheckoutPermission
    })
  )
  const [selectedCustomer, setSelectedCustomer] =
    useState<CashDeskCustomerFieldsFragment | null>(null)
  const leadFormMethods = useForm<ILeadForm>()
  const {
    watch: watchLeadForm,
    reset: resetLeadForm,
    triggerValidation: triggerLeadFormValidation
  } = leadFormMethods
  const handleTabChange = useCallback(
    (e, newValue) => {
      resetLeadForm()
      setTab(newValue)
    },
    [resetLeadForm]
  )
  const {retailLeadOptions, retailReservationLeadOptions} = effectiveClient
  const email = watchLeadForm(LeadField.Email)
  const isReadVoucherByCodePermitted = P([
    PermissionCode.ReadVoucherByCodeOnRetailChannel
  ])
  const [checkoutPaymentMethodsViewMode] =
    useLocalStorageState<CheckoutPaymentMethodsViewMode>(
      LocalStorageKey.CheckoutPaymentMethodsViewMode,
      CheckoutPaymentMethodsViewMode.ExpandedFirstGroup
    )
  const [
    {
      typeIntentStructures,
      groupIntentStructures,
      inHouseVoucherRowStructure,
      inHouseVoucherIntentStructure,
      expandedStructureKey
    },
    intentStructureDispatch
  ] = useReducer<Reducer<IntentsStructureReducer, IntentStructureAction>>(
    intentsStructureReducer,
    getIntentsStructureDefaultState(
      paymentMethods,
      isReadVoucherByCodePermitted,
      checkoutPaymentMethodsViewMode
    )
  )
  const hasPaymentMethods = useMemo(
    () => paymentMethods.length > 0,
    [paymentMethods.length]
  )
  const paymentIntentsFormMethods = useForm<IPaymentIntentsForm>()
  const {
    watch: watchPaymentIntentsForm,
    triggerValidation: triggerPaymentIntentsFormValidation
  } = paymentIntentsFormMethods
  const reserve = useReserve()
  const checkout = useCheckout()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const ticketPrintingTemplates = useGetTicketPrintingTemplates()

  const showPosTerminalAppSettingsError = useShowPosTerminalAppSettingsError()

  const handleCheckoutSubmit = useCallback(
    async (form: ILeadForm) => {
      try {
        const arePaymentIntentsValid =
          await triggerPaymentIntentsFormValidation()
        if (!arePaymentIntentsValid) {
          return
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
      }
      setShowBackdrop(true)
      const paymentIntentsForm = watchPaymentIntentsForm({
        nest: true
      })
      const paymentIntentInputs = getPaymentIntentsInputs(
        paymentIntentsForm,
        currentCart!.price
      )
      if (
        paymentIntentInputs.find(
          (pii) => pii.isManagedByThirdPartyLocalDevice
        ) &&
        !(await getIsPosTerminalAppConnectionValid())
      ) {
        setShowBackdrop(false)
        showPosTerminalAppSettingsError()
      } else {
        try {
          const {data} = await checkout({
            cartId: currentCart!.id,
            paymentIntentInputs,
            ...getCustomerParamsForMutation({leadData: form, selectedCustomer})
          })
          if (data) {
            updateCurrentCart()
            openCashDrawer(CashDrawerOpenLocation.AfterCheckout)
            if (
              [CartState.Sold, CartState.Pending].includes(
                data.checkout.state
              ) &&
              data.checkout.sales &&
              ticketPrintingTemplates.length > 0
            ) {
              addPrintingTemplateDataToHash({
                templateId: ticketPrintingTemplates[0].id,
                templateFileType: ticketPrintingTemplates[0].fileType,
                uuid: data.checkout.uuid,
                hash: data.checkout.hash
              })
            }
          }
        } catch (e) {
          checkoutErrorHandler(e)
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      setShowBackdrop,
      watchPaymentIntentsForm,
      currentCart,
      triggerPaymentIntentsFormValidation,
      showPosTerminalAppSettingsError,
      checkout,
      selectedCustomer,
      updateCurrentCart,
      openCashDrawer,
      ticketPrintingTemplates,
      addPrintingTemplateDataToHash,
      checkoutErrorHandler
    ]
  )
  const handleCheckoutAndSendByEmailSubmit = useCallback(async () => {
    try {
      const arePaymentIntentsValid = await triggerPaymentIntentsFormValidation()
      if (!arePaymentIntentsValid) {
        return
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return
    }
    try {
      setShowBackdrop(true)
      const isLeadFormValid = await triggerLeadFormValidation()
      if (!isLeadFormValid) {
        return
      }
      const form = watchLeadForm()
      const paymentIntentsForm = watchPaymentIntentsForm({
        nest: true
      })
      const {data} = await checkout({
        cartId: currentCart!.id,
        ...getCustomerParamsForMutation({leadData: form, selectedCustomer}),
        paymentIntentInputs: getPaymentIntentsInputs(
          paymentIntentsForm,
          currentCart!.price
        ),
        sendEmail: true
      })
      if (data) {
        updateCurrentCart()
        openCashDrawer(CashDrawerOpenLocation.AfterCheckout)
      }
    } catch (e) {
      checkoutErrorHandler(e)
    } finally {
      setShowBackdrop(false)
    }
  }, [
    triggerPaymentIntentsFormValidation,
    setShowBackdrop,
    triggerLeadFormValidation,
    watchLeadForm,
    watchPaymentIntentsForm,
    checkout,
    currentCart,
    selectedCustomer,
    updateCurrentCart,
    openCashDrawer,
    checkoutErrorHandler
  ])
  const handleReservationSubmit = useCallback(
    async (form: ILeadForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await reserve({
          cartId: currentCartId!,
          ...getCustomerParamsForMutation({leadData: form, selectedCustomer})
        })
        if (data) {
          updateCurrentCart()
        }
      } catch (e) {
        defaultErrorHandler(e, t('Reservation failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      reserve,
      currentCartId,
      selectedCustomer,
      updateCurrentCart,
      defaultErrorHandler,
      t
    ]
  )

  const handleCreateAndSendByEmailButtonClick = useCallback(async () => {
    try {
      const isLeadFormValid = await triggerLeadFormValidation()
      if (!isLeadFormValid) {
        return
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return
    }
    try {
      setShowBackdrop(true)
      const form = watchLeadForm()
      const {data} = await reserve({
        cartId: currentCartId!,
        sendEmail: true,
        ...getCustomerParamsForMutation({leadData: form, selectedCustomer})
      })
      if (data) {
        updateCurrentCart()
      }
    } catch (e) {
      defaultErrorHandler(e, t('Reservation failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    triggerLeadFormValidation,
    setShowBackdrop,
    watchLeadForm,
    reserve,
    currentCartId,
    selectedCustomer,
    updateCurrentCart,
    defaultErrorHandler,
    t
  ])

  const downloadSalesPdf = useDownloadSalesPdf()
  const handlePayAndDownloadPdfButtonClick = useCallback(async () => {
    try {
      const arePaymentIntentsValid = await triggerPaymentIntentsFormValidation()
      if (!arePaymentIntentsValid) {
        return
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return
    }
    try {
      setShowBackdrop(true)
      const isLeadFormValid = await triggerLeadFormValidation()
      if (!isLeadFormValid) {
        return
      }
      const form = watchLeadForm()
      const paymentIntentsForm = watchPaymentIntentsForm({
        nest: true
      })
      const {data} = await checkout({
        cartId: currentCart!.id,
        ...getCustomerParamsForMutation({leadData: form, selectedCustomer}),
        paymentIntentInputs: getPaymentIntentsInputs(
          paymentIntentsForm,
          currentCart!.price
        )
      })
      if (data) {
        updateCurrentCart()
        openCashDrawer(CashDrawerOpenLocation.AfterCheckout)
        if (data.checkout.sales) {
          await downloadSalesPdf(data.checkout.sales)
        }
      }
    } catch (e) {
      checkoutErrorHandler(e)
    } finally {
      setShowBackdrop(false)
    }
  }, [
    triggerPaymentIntentsFormValidation,
    setShowBackdrop,
    triggerLeadFormValidation,
    watchLeadForm,
    watchPaymentIntentsForm,
    checkout,
    currentCart,
    selectedCustomer,
    updateCurrentCart,
    openCashDrawer,
    downloadSalesPdf,
    checkoutErrorHandler
  ])

  const handleTicketPrintingTemplateButtonClick = useCallback(
    async (template: TemplatesQuery['templates'][number]) => {
      try {
        const arePaymentIntentsValid =
          await triggerPaymentIntentsFormValidation()
        if (!arePaymentIntentsValid) {
          return
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
      }
      try {
        setShowBackdrop(true)
        const isLeadFormValid = await triggerLeadFormValidation()
        if (!isLeadFormValid) {
          return
        }
        const form = watchLeadForm()
        const paymentIntentsForm = watchPaymentIntentsForm({
          nest: true
        })
        const {data} = await checkout({
          cartId: currentCart!.id,
          ...getCustomerParamsForMutation({leadData: form, selectedCustomer}),
          paymentIntentInputs: getPaymentIntentsInputs(
            paymentIntentsForm,
            currentCart!.price
          )
        })
        if (data) {
          updateCurrentCart()
          openCashDrawer(CashDrawerOpenLocation.AfterCheckout)
          if (
            [CartState.Sold, CartState.Pending].includes(data.checkout.state) &&
            data.checkout.sales
          ) {
            addPrintingTemplateDataToHash({
              templateId: template.id,
              templateFileType: template.fileType,
              uuid: data.checkout.uuid,
              hash: data.checkout.hash
            })
          }
        }
      } catch (e) {
        checkoutErrorHandler(e)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      triggerPaymentIntentsFormValidation,
      setShowBackdrop,
      triggerLeadFormValidation,
      watchLeadForm,
      watchPaymentIntentsForm,
      checkout,
      currentCart,
      selectedCustomer,
      updateCurrentCart,
      openCashDrawer,
      addPrintingTemplateDataToHash,
      checkoutErrorHandler
    ]
  )

  const paymentIntentsForm = watchPaymentIntentsForm({
    nest: true
  })

  const getStructureExtendHandler = useCallback(
    (structure: {key: string}) => () => {
      intentStructureDispatch({
        type: IntentStructureActionType.ExtendStructure,
        payload: structure.key
      })
    },
    []
  )
  const handleCustomersDrawerExited = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.cartDetail())
  }, [history])

  const handleRemoveSelectedCustomer = useCallback(() => {
    setSelectedCustomer(null)
    resetLeadForm(
      Object.fromEntries(
        Object.keys(leadFormMethods.getValues()).map((key) => [key, null])
      )
    )
  }, [leadFormMethods, resetLeadForm])
  return (
    <div className={className}>
      <div className={classes.header}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {hasCheckoutPermission && (
            <Tab label={t('Payment')} value={CartTabs.PAYMENT} />
          )}
          {hasReservePermission && (
            <Tab label={t('Reservation')} value={CartTabs.RESERVATION} />
          )}
        </Tabs>
      </div>
      <div className={classes.content}>
        <div className={classes.scrollableBox}>
          {tab === CartTabs.RESERVATION && hasReservePermission && (
            <ChildrenOnValidCartCheckoutOptions
              expectedCheckoutOption={CheckoutOptions.Reservation}
            >
              <div className={classes.contentColumn}>
                {retailReservationLeadOptions.some(
                  isNotUnavailableLeadOptionFilter
                ) ? (
                  <FormContext {...leadFormMethods}>
                    {selectedCustomer && (
                      <BaseCustomerCard
                        customer={selectedCustomer}
                        icon={
                          <IconButton
                            size="small"
                            onClick={handleRemoveSelectedCustomer}
                          >
                            <CancelIcon />
                          </IconButton>
                        }
                      />
                    )}
                    <LeadDetail
                      leadOptions={retailReservationLeadOptions}
                      onSubmit={handleReservationSubmit}
                      selectedCustomer={selectedCustomer}
                    />
                  </FormContext>
                ) : (
                  <Blank
                    title={t('All lead inputs are unavailable.')}
                    description={t(
                      'It can be changed in your company settings.'
                    )}
                  />
                )}
              </div>
            </ChildrenOnValidCartCheckoutOptions>
          )}
          {tab === CartTabs.PAYMENT && hasCheckoutPermission && (
            <ChildrenOnValidPaymentMethod
              hasValidPaymentMethod={hasPaymentMethods}
            >
              <ChildrenOnValidCartCheckoutOptions
                expectedCheckoutOption={CheckoutOptions.Sale}
              >
                <div className={classes.contentColumn}>
                  {retailLeadOptions.some(isNotUnavailableLeadOptionFilter) ? (
                    <FormContext {...leadFormMethods}>
                      {selectedCustomer && (
                        <BaseCustomerCard
                          customer={selectedCustomer}
                          icon={
                            <IconButton
                              size="small"
                              onClick={handleRemoveSelectedCustomer}
                            >
                              <CancelIcon />
                            </IconButton>
                          }
                        />
                      )}
                      <LeadDetail
                        leadOptions={retailLeadOptions}
                        onSubmit={handleCheckoutSubmit}
                        selectedCustomer={selectedCustomer}
                      />
                    </FormContext>
                  ) : (
                    <Paper
                      sx={{
                        px: 2,
                        py: 1,
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        alignItems: 'center',
                        gap: 1.5
                      }}
                      variant="outlined"
                    >
                      <PersonIcon />
                      <Typography variant="subtitle1">
                        {t('Customer')}
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          history.replace(
                            routeTo.admin.cashDesk.cartDetailWithCustomers()
                          )
                        }
                      >
                        <PersonSearchIcon />
                      </IconButton>
                    </Paper>
                  )}
                  <IntentsStructureDispatchContext.Provider
                    value={intentStructureDispatch}
                  >
                    <FormContext {...paymentIntentsFormMethods}>
                      <div className={classes.frames}>
                        {typeIntentStructures.map((structure) => (
                          <TypeIntentStructure
                            key={structure.type}
                            structure={structure}
                            isExpanded={expandedStructureKey === structure.key}
                            onStructureExpand={getStructureExtendHandler(
                              structure
                            )}
                          />
                        ))}
                        {inHouseVoucherIntentStructure.map((structure) => (
                          <InHouseVoucherIntentStructure
                            key={structure.campaign.id}
                            structure={structure}
                            onStructureExpand={getStructureExtendHandler(
                              structure
                            )}
                            isExpanded={expandedStructureKey === structure.key}
                          />
                        ))}
                        {groupIntentStructures.map((structure) => (
                          <GroupIntentStructure
                            key={structure.group.id}
                            structure={structure}
                            isExpanded={expandedStructureKey === structure.key}
                            onStructureExpand={getStructureExtendHandler(
                              structure
                            )}
                          />
                        ))}
                        {inHouseVoucherRowStructure && (
                          <InHouseVoucherRowStructure
                            cartPrice={currentCart?.price || 0}
                            structure={inHouseVoucherRowStructure}
                            onStructureExpand={getStructureExtendHandler(
                              inHouseVoucherRowStructure
                            )}
                            isExpanded={
                              expandedStructureKey ===
                              inHouseVoucherRowStructure.key
                            }
                          />
                        )}
                      </div>
                    </FormContext>
                  </IntentsStructureDispatchContext.Provider>
                </div>
              </ChildrenOnValidCartCheckoutOptions>
            </ChildrenOnValidPaymentMethod>
          )}
        </div>
      </div>
      {tab === CartTabs.RESERVATION &&
        cartSatisfiesExpectedCheckoutOption(
          currentCart,
          CheckoutOptions.Reservation
        ) &&
        hasReservePermission && (
          <ReservationFooter
            onCreateAndSendByEmailButtonClick={
              handleCreateAndSendByEmailButtonClick
            }
            isLeadReservationEmailEnabled={effectiveClient.retailReservationLeadOptions.some(
              (option) =>
                option.field === LeadField.Email &&
                option.inputStatus !== LeadInputStatus.Unavailable
            )}
            leadEmail={email}
          />
        )}
      {tab === CartTabs.PAYMENT &&
        hasPaymentMethods &&
        cartSatisfiesExpectedCheckoutOption(
          currentCart,
          CheckoutOptions.Sale
        ) &&
        hasCheckoutPermission && (
          <PaymentFooter
            defaultEmail={email || undefined}
            surplus={getSurplus(currentCart?.price || 0, paymentIntentsForm)}
            isLeadReservationEmailEnabled={effectiveClient.retailLeadOptions.some(
              (option) =>
                option.field === LeadField.Email &&
                option.inputStatus !== LeadInputStatus.Unavailable
            )}
            onSendByEmailButtonClick={handleCheckoutAndSendByEmailSubmit}
            onPayAndDownloadPdfButtonClick={handlePayAndDownloadPdfButtonClick}
            onTicketPrintingTemplateButtonClick={
              handleTicketPrintingTemplateButtonClick
            }
          />
        )}
      <Route path={routeTo.admin.cashDesk.cartDetailWithCustomers()}>
        <CustomersDrawer
          onExited={handleCustomersDrawerExited}
          onCustomerSelect={setSelectedCustomer}
        />
      </Route>
    </div>
  )
}

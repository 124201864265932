import {
  Box,
  Card,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback, useMemo} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {EventExpirationType} from '../../../../../../__generated__/schema'
import {Theme} from '../../../../../../theme'
import {
  daysItems,
  hoursItems,
  minutesItems
} from '../../../../../../utils/durationInput'
import {
  useIsIntegerWithMaxBoundary,
  useIsNonNegativeInteger
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {FormDatetimeInput} from '../../../../../form/pickers'
import {FormFieldName} from '../../../../../form/types'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {FormField, ISalesFormData, SectionPrefix} from './common'

const useGetSecondaryLabelText = (sectionPrefix: SectionPrefix) => {
  const {t} = useTranslation()
  const getSalesLabelText = useCallback(
    (isActive: boolean) => {
      if (sectionPrefix === SectionPrefix.POINT_OF_SALE) {
        return isActive
          ? t(
              'Cashiers are allowed to sold tickets on event in specified period.'
            )
          : t('Cashiers are not allowed to sold tickets on event.')
      } else {
        return isActive
          ? t(
              'Customers are allowed to purchase tickets on event in specified period.'
            )
          : t('Customers are not allowed to purchase tickets on event.')
      }
    },
    [sectionPrefix, t]
  )
  const getReservationLabelText = useCallback(
    (isActive: boolean) => {
      if (sectionPrefix === SectionPrefix.POINT_OF_SALE) {
        return isActive
          ? t(
              'Cashiers are allowed to create new reservations on event in specified period.'
            )
          : t('Cashiers are not allowed to create new reservations on event.')
      } else {
        return isActive
          ? t(
              'Customers are allowed to create new reservations on event in specified period.'
            )
          : t('Customers are not allowed to create new reservations on event.')
      }
    },
    [sectionPrefix, t]
  )
  const getMaxOccupiedSeatsLabelText = useCallback(
    (capacity: number) =>
      sectionPrefix === SectionPrefix.POINT_OF_SALE
        ? t(
            'Disable adding more items to cart or reservation on retail, when number of occupied seats reached defined limit. Limit can be set in interval between 0 and {{capacity}}. Value 0 means no items can be occupied on this channel. Occupied seats are all seats in other state than available.',
            {capacity}
          )
        : t(
            'Disable adding more items to cart or reservation on ecommerce, when number of occupied seats reached defined limit. Limit can be set in interval between 0 and {{capacity}}. Value 0 means no items can be occupied on this channel. Occupied seats are all seats in other state than available.',
            {capacity}
          ),
    [sectionPrefix, t]
  )
  return useMemo(
    () => ({
      getSalesLabelText,
      getReservationLabelText,
      getMaxOccupiedSeatsLabelText
    }),
    [getSalesLabelText, getReservationLabelText, getMaxOccupiedSeatsLabelText]
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    padding: 0,
    margin: 0
  },
  wrapper: {
    padding: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  dateForm: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  lastsForSelectsWrapper: {
    marginTop: theme.spacing(1.5)
  },
  lastsForSelect: {
    width: 100
  },
  radioGroup: {
    height: '100%',
    justifyContent: 'space-around'
  },
  expiresAt: {
    width: 285
  }
}))

interface ILabelProps {
  mainLabel: string
  secondaryLabel?: string
}

const Label: React.FC<ILabelProps> = ({
  mainLabel,
  secondaryLabel
}: ILabelProps) => {
  return (
    <Box paddingBottom={2}>
      <Typography component="div" variant="caption">
        <Box fontWeight="fontWeightBold">{mainLabel}</Box>
      </Typography>
      {secondaryLabel && (
        <Typography variant="caption" color="textSecondary">
          {secondaryLabel}
        </Typography>
      )}
    </Box>
  )
}

interface ISwitchableSectionProps extends ILabelProps {
  switchInputName: string
}

const SwitchableSection: React.FC<ISwitchableSectionProps> = ({
  mainLabel,
  secondaryLabel,
  switchInputName
}: ISwitchableSectionProps) => {
  const {control} = useFormContext<ISalesFormData>()
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Label {...{mainLabel, secondaryLabel}} />
      </Grid>
      <Controller
        as={<Switch color="primary" />}
        control={control}
        name={switchInputName}
      />
    </Grid>
  )
}

interface IStartAndEndDateFieldsProps {
  startInputName: string
  endInputName: string
  eventCreatedAt: string
}

const StartAndEndDateFields: React.FC<IStartAndEndDateFieldsProps> = ({
  startInputName,
  endInputName,
  eventCreatedAt
}: IStartAndEndDateFieldsProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    control,
    clearError,
    setError,
    unregister,
    watch,
    register,
    setValue,
    errors
  } = useFormContext<ISalesFormData>()

  return (
    <Grid container spacing={2} className={classes.dateForm}>
      <Grid item xs={6}>
        <FormDatetimeInput
          name={startInputName as keyof ISalesFormData}
          dataTimePickerProps={{
            label: t('Start'),
            minDateTime: dayjs(eventCreatedAt)
          }}
          validationOptions={{
            required: true
          }}
          register={register}
          unregister={unregister}
          control={control}
          setValue={setValue}
          watch={watch}
          clearError={clearError}
          errors={errors}
          setError={setError}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormDatetimeInput
          name={endInputName as keyof ISalesFormData}
          dataTimePickerProps={{
            label: t('End'),
            minDateTime: dayjs(eventCreatedAt)
          }}
          validationOptions={{
            required: true
          }}
          register={register}
          unregister={unregister}
          control={control}
          setValue={setValue}
          watch={watch}
          clearError={clearError}
          errors={errors}
          setError={setError}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

interface IReservationTypeOptionsProps {
  sectionPrefix: SectionPrefix
  eventCreatedAt: string
}

const ReservationTypeOptions: React.FC<IReservationTypeOptionsProps> = ({
  sectionPrefix,
  eventCreatedAt
}: IReservationTypeOptionsProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    register,
    control,
    setValue,
    errors,
    unregister,
    watch,
    clearError,
    setError
  } = useFormContext<ISalesFormData>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Controller
          as={
            <RadioGroup className={classes.radioGroup}>
              <FormControlLabel
                value={EventExpirationType.ExpiresAt}
                control={<Radio color="primary" />}
                label={t('Specific date and time')}
              />
              <FormControlLabel
                value={EventExpirationType.LastFor}
                control={<Radio color="primary" />}
                label={t('Lasts for')}
              />
            </RadioGroup>
          }
          control={control}
          name={`${sectionPrefix}[${FormField.RESERVATION_EXPIRATION_TYPE}]`}
        />
      </Grid>
      <Grid item xs={7}>
        <Box className={classes.expiresAt}>
          <FormDatetimeInput
            name={
              `${sectionPrefix}[${FormField.RESERVATION_EXPIRATION_EXPIRES_AT}]` as keyof ISalesFormData
            }
            dataTimePickerProps={{
              label: t('Expire at'),
              minDateTime: dayjs(eventCreatedAt)
            }}
            register={register}
            unregister={unregister}
            control={control}
            setValue={setValue}
            watch={watch}
            clearError={clearError}
            errors={errors}
            setError={setError}
            fullWidth
          />
        </Box>
        <Grid container spacing={2} className={classes.lastsForSelectsWrapper}>
          <Grid item className={classes.lastsForSelect}>
            <UncontrolledFormSelect<ISalesFormData>
              name={
                `${sectionPrefix}[${FormField.RESERVATION_EXPIRATION_LAST_FOR}][days]` as keyof ISalesFormData
              }
              label={t('Days')}
              selectOptions={daysItems}
              validationOptions={{
                required: true
              }}
              fullWidth
              setValue={setValue}
              errors={errors}
              watch={watch}
              register={register}
            />
          </Grid>
          <Grid item className={classes.lastsForSelect}>
            <UncontrolledFormSelect<ISalesFormData>
              name={
                `${sectionPrefix}[${FormField.RESERVATION_EXPIRATION_LAST_FOR}][hours]` as keyof ISalesFormData
              }
              label={t('Hours')}
              selectOptions={hoursItems}
              validationOptions={{
                required: true
              }}
              fullWidth
              setValue={setValue}
              errors={errors}
              watch={watch}
              register={register}
            />
          </Grid>
          <Grid item className={classes.lastsForSelect}>
            <UncontrolledFormSelect<ISalesFormData>
              name={
                `${sectionPrefix}[${FormField.RESERVATION_EXPIRATION_LAST_FOR}][minutes]` as keyof ISalesFormData
              }
              label={t('Minutes')}
              selectOptions={minutesItems}
              validationOptions={{
                required: true
              }}
              fullWidth
              setValue={setValue}
              errors={errors}
              watch={watch}
              register={register}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface ISalesFormFieldsProps {
  id: string
  header: string
  sectionPrefix: SectionPrefix
  eventCreatedAt: string
  auditoriumCapacity: number
  defaultValues?: Partial<ISalesFormData>
}

export const SalesFormFields: React.FC<ISalesFormFieldsProps> = ({
  id,
  header,
  sectionPrefix,
  eventCreatedAt,
  auditoriumCapacity,
  defaultValues
}: ISalesFormFieldsProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    watch,
    getValues,
    errors,
    setValue,
    register,
    triggerValidation,
    control
  } = useFormContext<ISalesFormData>()
  const {
    getSalesLabelText,
    getReservationLabelText,
    getMaxOccupiedSeatsLabelText
  } = useGetSecondaryLabelText(sectionPrefix)

  const isIntegerWithMaxBoundary =
    useIsIntegerWithMaxBoundary(auditoriumCapacity)
  const isNonNegativeInteger = useIsNonNegativeInteger()

  watch(`${sectionPrefix}[${FormField.SALE_ACTIVE}]`)
  watch(`${sectionPrefix}[${FormField.RESERVATION_ACTIVE}]`)

  const values = getValues({nest: true})
  const showSalesCard = values[sectionPrefix][FormField.SALE_ACTIVE]
  const showReservationsCard =
    values[sectionPrefix][FormField.RESERVATION_ACTIVE]
  const isEnabledPaymentForReservation = watch(
    `${sectionPrefix}[${FormField.IS_ENABLED_PAYMENT_FOR_RESERVATION}]`
  )

  return (
    <Card className={classes.card} id={id}>
      <Box className={classes.wrapper}>
        <Typography variant="h5" className={classes.header}>
          {header}
        </Typography>
        <SwitchableSection
          mainLabel={t('Sales')}
          secondaryLabel={getSalesLabelText(showSalesCard)}
          switchInputName={`${sectionPrefix}[${FormField.SALE_ACTIVE}]`}
        />
        <Collapse in={showSalesCard}>
          <StartAndEndDateFields
            startInputName={`${sectionPrefix}[${FormField.SALE_START}]`}
            endInputName={`${sectionPrefix}[${FormField.SALE_END}]`}
            eventCreatedAt={eventCreatedAt}
          />
        </Collapse>
      </Box>
      <Divider />
      <Box className={classes.wrapper}>
        <SwitchableSection
          mainLabel={t('Reservations')}
          secondaryLabel={getReservationLabelText(showReservationsCard)}
          switchInputName={`${sectionPrefix}[${FormField.RESERVATION_ACTIVE}]`}
        />
        <Collapse in={showReservationsCard}>
          <Label mainLabel={t('Creating new reservations')} />
          <StartAndEndDateFields
            startInputName={`${sectionPrefix}[${FormField.RESERVATION_START}]`}
            endInputName={`${sectionPrefix}[${FormField.RESERVATION_END}]`}
            eventCreatedAt={eventCreatedAt}
          />
          <Label
            mainLabel={t('Expiration of new reservations')}
            secondaryLabel={t(
              'Default expiration time for all newly created reservations. Updating this option will not affect already created and active reservations on event.'
            )}
          />
          <ReservationTypeOptions {...{sectionPrefix, eventCreatedAt}} />
        </Collapse>
      </Box>
      <Box className={classes.wrapper}>
        {sectionPrefix === SectionPrefix.POINT_OF_SALE ? (
          <InputRow
            nodes={[
              <FormSwitchWithLabels<ISalesFormData>
                name={
                  `${sectionPrefix}[${FormField.IS_ENABLED_PAYMENT_FOR_RESERVATION}]` as FormFieldName<ISalesFormData>
                }
                key={`${sectionPrefix}[${FormField.IS_ENABLED_PAYMENT_FOR_RESERVATION}]`}
                primaryLabel={t(
                  'Enable payment for reservations on retail channel ?'
                )}
                primaryLabelTypographyProps={{variant: 'subtitle2'}}
                control={control}
                watch={watch}
                defaultValue={
                  defaultValues?.pointOfSale?.isEnabledPaymentForReservation ||
                  true
                }
                secondaryLabel={
                  isEnabledPaymentForReservation
                    ? t('Cashiers are allowed to make payment for reservation.')
                    : t(
                        'Cashiers are not allowed to make payment for reservation.'
                      )
                }
                enabledText=""
                disabledText=""
              />
            ]}
          />
        ) : (
          <InputRow
            nodes={[
              <FormSwitchWithLabels<ISalesFormData>
                name={
                  `${sectionPrefix}[${FormField.IS_ENABLED_PAYMENT_FOR_RESERVATION}]` as FormFieldName<ISalesFormData>
                }
                key={`${sectionPrefix}[${FormField.IS_ENABLED_PAYMENT_FOR_RESERVATION}]`}
                primaryLabel={t(
                  'Enable payment for reservations on ecommerce channel ?'
                )}
                primaryLabelTypographyProps={{variant: 'subtitle2'}}
                control={control}
                watch={watch}
                defaultValue={
                  defaultValues?.online?.isEnabledPaymentForReservation || true
                }
                secondaryLabel={
                  isEnabledPaymentForReservation
                    ? t(
                        'Customers are allowed to make payment for reservation.'
                      )
                    : t(
                        'Customers are not allowed to make payment for reservation.'
                      )
                }
                enabledText=""
                disabledText=""
              />
            ]}
          />
        )}
      </Box>
      <Box className={classes.wrapper}>
        <Label
          mainLabel={
            sectionPrefix === SectionPrefix.POINT_OF_SALE
              ? t('Disable retail after reaching limit')
              : t('Disable ecommerce after reaching limit')
          }
          secondaryLabel={getMaxOccupiedSeatsLabelText(auditoriumCapacity)}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              validationOptions={{
                required: true,
                validate: {isNonNegativeInteger, isIntegerWithMaxBoundary}
              }}
              label={t('Maximum occupied seats')}
              name={
                `${sectionPrefix}[${FormField.MAX_NUMBER_OF_OCCUPIED_SEATS}]` as FormFieldName<ISalesFormData>
              }
              key={`${sectionPrefix}[${FormField.RESERVATION_START}]`}
              fullWidth
              required
            />
          ]}
          xs={6}
        />
      </Box>
    </Card>
  )
}

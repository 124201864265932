import CreditCardIcon from '@mui/icons-material/CreditCard'
import {Box, Button} from '@mui/material'
import axios from 'axios'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {ClaimPropertiesFragment} from '../../../../__generated__/schema'
import {
  PosTerminalApiResponseState,
  PosTerminalApiTerminalRequest,
  PosTerminalApiTerminalResponse
} from '../../../../types'
import {
  getPosTerminalCurrency,
  getTerminalConfig
} from '../../../../utils/getTerminalConfig'
import {routeTo} from '../../../../utils/routes'
import {formatJsonToPreview, JsonPreview} from '../../../common/JsonPreview'
import {SimpleDialog} from '../../../common/SimpleDialog'
import {Blank} from '../../../visual/Blank'
import {getPosTerminalAppSettings} from '../cashDesk/cart/posTerminalAppUtils'

interface IPendingClaimCoreProps {
  claim: ClaimPropertiesFragment
}

export const PendingClaimCore: React.FC<IPendingClaimCoreProps> = ({
  claim
}: IPendingClaimCoreProps) => {
  const {t} = useTranslation()
  const {port} = getPosTerminalAppSettings() || {}
  const [dialogData, setDialogData] =
    useState<null | {
      title: string
      content: string
      response?: string
    }>(null)
  const history = useHistory()
  const handleMount = useCallback(
    async (thirdPartyRefundNotifyUrl: string) => {
      if (port) {
        const errorDialogData = {
          title: t('POS terminal refund error'),
          content: t(
            'We encountered an error processing the refund on the POS terminal. This could be due to various technical issues. Ensure the POS terminal has an internet connection and is turned on. Verify that the communication app directing commands to the POS terminal is running and has the correct configuration set. If problems continue, consider using alternative payment methods or seek technical support.'
          )
        }
        try {
          const {
            data: {state, rawPosResponse}
          } = await axios.request<PosTerminalApiTerminalResponse>(
            getTerminalConfig<PosTerminalApiTerminalRequest>({
              port,
              url: '/terminal/refund',
              method: 'POST',
              timeout: 120000,
              data: {
                returnUrl: thirdPartyRefundNotifyUrl,
                data: {
                  amount: new Decimal(claim.price).mul(100).toNumber(),
                  clientId: claim.division.client.id,
                  currency: getPosTerminalCurrency(
                    claim.division.client.currency
                  ),
                  externalId: String(claim.id)
                }
              }
            })
          )
          switch (state) {
            case PosTerminalApiResponseState.cancelled:
              setDialogData({
                title: t('Refund canceled'),
                content: t(
                  'The refund via the POS terminal was canceled by the operator or customer. Please repeat the payment process.'
                ),
                response: formatJsonToPreview(rawPosResponse)
              })
              break
            case PosTerminalApiResponseState.failed:
              setDialogData({
                title: t('Refund failed'),
                content: t('Refund on the POS terminal was unsuccessful.'),
                response: formatJsonToPreview(rawPosResponse)
              })
              break
            case PosTerminalApiResponseState.error:
              setDialogData({
                ...errorDialogData,
                response: formatJsonToPreview(rawPosResponse)
              })
              break
            case PosTerminalApiResponseState.succeeded:
              history.replace(routeTo.admin.claims.detail(claim.id))
              break
            default:
              break
          }
        } catch (error) {
          setDialogData({
            ...errorDialogData,
            response: error.response.data.rawPosResponse
              ? formatJsonToPreview(error.response.data.rawPosResponse)
              : undefined
          })
        }
      }
    },
    [
      claim.division.client.currency,
      claim.division.client.id,
      claim.id,
      claim.price,
      history,
      port,
      t
    ]
  )
  useEffect(
    () => {
      if (claim.thirdPartyRefundNotifyUrl) {
        handleMount(claim.thirdPartyRefundNotifyUrl)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <>
      <Blank
        IconComp={CreditCardIcon}
        title={t('Refund in progress')}
        description={t(
          'The refund is currently being processed via the POS terminal. Please closely follow the instructions displayed on the POS terminal screen. Do not reload this screen.'
        )}
      />
      {dialogData ? (
        <SimpleDialog
          content={
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
              {dialogData.content}
              {dialogData.response && (
                <JsonPreview stringifiedJson={dialogData.response} />
              )}
            </Box>
          }
          title={dialogData.title}
          actions={
            <Button
              onClick={() => {
                setDialogData(null)
              }}
            >
              {t('Got it')}
            </Button>
          }
          isOpen
        />
      ) : null}
    </>
  )
}

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {EventState} from '../../../../../__generated__/schema'
import {InputRow} from '../../../../common'
import {FormDatetimeInput} from '../../../../form/pickers'
import {CopyEventFormField, ICopyEventForm} from './types'

const COPY_EVENT_FORM_ID = 'copyEventForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(2)
}))

interface ICopyEventDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: ICopyEventForm) => Promise<void>
  defaultValues?: Partial<ICopyEventForm>
  title: string
  description?: string
  divisionName?: string
}

export const CopyEventDialog: React.FC<ICopyEventDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValues,
  title,
  description,
  divisionName
}: ICopyEventDialogProps) => {
  const {t} = useTranslation()
  const {
    register,
    errors,
    setValue,
    clearError,
    unregister,
    setError,
    watch,
    control,
    handleSubmit
  } = useForm<ICopyEventForm>({
    defaultValues
  })
  const handleEventStateChange = useCallback(
    (state: EventState) => () => {
      setValue(CopyEventFormField.State, state)
    },
    [setValue]
  )
  const selectedEventState = watch(CopyEventFormField.State)
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitle>
        <Typography component="div" variant="h6">
          {t('Copy event')}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{pb: 2}}>
          <Typography variant="subtitle2">{title}</Typography>
          {description && (
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          )}
          {divisionName && (
            <Typography variant="body2" color="textSecondary">
              {divisionName}
            </Typography>
          )}
        </Box>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={COPY_EVENT_FORM_ID}>
          <input
            type="hidden"
            name={CopyEventFormField.State}
            ref={register()}
          />
          <InputRow
            nodes={[
              <FormDatetimeInput<ICopyEventForm>
                dataTimePickerProps={{
                  label: t<string>('Destination date and time'),
                  disablePast: true
                }}
                control={control}
                register={register}
                unregister={unregister}
                watch={watch}
                errors={errors}
                clearError={clearError}
                setValue={setValue}
                key={CopyEventFormField.DestionationDateTime}
                name={CopyEventFormField.DestionationDateTime}
                setError={setError}
                validationOptions={{
                  required: true
                }}
                fullWidth
              />
            ]}
          />
          <Box>
            <Typography variant="body2">
              {t('Destination event state')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                flexWrap: 'wrap',
                pt: 1
              }}
            >
              <Chip
                key={EventState.Draft}
                label={t('Draft')}
                onClick={handleEventStateChange(EventState.Draft)}
                variant={
                  selectedEventState === EventState.Draft
                    ? 'filled'
                    : 'outlined'
                }
                color={
                  selectedEventState === EventState.Draft
                    ? 'primary'
                    : undefined
                }
              />
              <Chip
                key={EventState.Published}
                label={t('Published')}
                onClick={handleEventStateChange(EventState.Published)}
                variant={
                  selectedEventState === EventState.Published
                    ? 'filled'
                    : 'outlined'
                }
                color={
                  selectedEventState === EventState.Published
                    ? 'primary'
                    : undefined
                }
              />
            </Box>
          </Box>
        </StyledForm>
      </DialogContent>
      <DialogActions sx={{px: 3, display: 'flex', gap: 2}}>
        <Button variant="text" color="primary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          variant="text"
          color="primary"
          startIcon={<ContentCopyIcon />}
          type="submit"
          form={COPY_EVENT_FORM_ID}
        >
          {t('Copy')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {useLazyQuery} from '@apollo/react-hooks'
import {Divider, MenuItem} from '@mui/material'
import {uniqBy} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DeviceSettingsWarehouseFieldsFragment,
  DivisionState,
  GetWarehousesForDeviceSettingsQuery,
  GetWarehousesForDeviceSettingsQueryVariables,
  NarrowDivisionPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {InputBlockWithoutSpacings} from '../../../common'
import {Loading} from '../../../visual'
import {GET_WAREHOUSES_FOR_DEVICE_SETTINGS} from './graphql'
import {RowWithSelect} from './RowWithSelect'
import {DeviceSettingsAction, DeviceSettingsActionTypes} from './types'

interface IShopSettingsBlockProps {
  blockId: string
  blockLabel: string
  divisions: NarrowDivisionPropertiesFragment[]
  selectedDivisionId: number | null
  dispatch: React.Dispatch<DeviceSettingsAction>
  selectedWarehouseId: number | null
}

export const ShopSettingsBlock: React.FC<IShopSettingsBlockProps> = ({
  blockId,
  blockLabel,
  divisions,
  selectedDivisionId,
  dispatch,
  selectedWarehouseId
}: IShopSettingsBlockProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [warehouses, setWarehouses] = useState<
    DeviceSettingsWarehouseFieldsFragment[]
  >([])
  const [getWarehouses, {loading: warehousesLoading}] = useLazyQuery<
    GetWarehousesForDeviceSettingsQuery,
    GetWarehousesForDeviceSettingsQueryVariables
  >(GET_WAREHOUSES_FOR_DEVICE_SETTINGS, {
    onCompleted: (data) => {
      setWarehouses(
        data.warehouses.items.sort((warehouseA, warehouseB) =>
          warehouseA.name.localeCompare(warehouseB.name)
        )
      )
      if (data.warehouses.items.length === 0) {
        dispatch({
          type: DeviceSettingsActionTypes.ChangeWarehouseId,
          payload: null
        })
      }
    },
    fetchPolicy: 'network-only'
  })
  const divisionItems = uniqBy(
    [
      ...divisions.filter((d) => d.id === selectedDivisionId),
      ...divisions.filter((d) => d.state === DivisionState.Active)
    ],
    'id'
  )
  useEffect(() => {
    if (selectedDivisionId && P([PermissionCode.ReadWarehouses])) {
      getWarehouses({
        variables: {
          filter: {divisionIds: [selectedDivisionId]},
          paginationInput: {offset: 0, limit: 100}
        }
      })
    }
  }, [P, getWarehouses, selectedDivisionId])
  return (
    <InputBlockWithoutSpacings blockId={blockId} header={blockLabel}>
      <RowWithSelect
        primaryLabel={t('Default division for products')}
        secondaryLabel={t('Required to display products and offer.')}
        selectProps={{
          value: selectedDivisionId ?? '',
          onChange: (e) => {
            dispatch({
              type: DeviceSettingsActionTypes.ChangeDivision,
              payload: e.target.value as number
            })
            if (P([PermissionCode.ReadWarehouses])) {
              getWarehouses({
                variables: {
                  filter: {divisionIds: [e.target.value as number]},
                  paginationInput: {offset: 0, limit: 100}
                }
              })
            }
          },
          children: divisionItems.map((di) => (
            <MenuItem key={di.id} value={di.id}>
              {di.name}
            </MenuItem>
          ))
        }}
      />
      {P([PermissionCode.ReadWarehouses]) && (
        <>
          <Divider />
          <RowWithSelect
            primaryLabel={t('Default warehouse for products')}
            secondaryLabel={t(
              'Required to display warehouse products and recipes.'
            )}
            selectProps={{
              value:
                warehouses.find(({id}) => id === selectedWarehouseId) &&
                !warehousesLoading
                  ? selectedWarehouseId
                  : '',
              onChange: (e) => {
                dispatch({
                  type: DeviceSettingsActionTypes.ChangeWarehouseId,
                  payload: e.target.value as number
                })
              },
              children: warehousesLoading ? (
                <Loading />
              ) : warehouses.length === 0 ? (
                <MenuItem key={0} value="none" disabled>
                  {t('No active warehouses found for selected division')}
                </MenuItem>
              ) : (
                warehouses.map(({id, name}) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))
              )
            }}
          />
        </>
      )}
    </InputBlockWithoutSpacings>
  )
}

import {useQuery} from '@apollo/react-hooks'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightProductTypesQuery,
  GetLightweightProductTypesQueryVariables,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  LightweightProductsQuery,
  LightweightProductsQueryVariables,
  PermissionCode,
  ProductState,
  WarehouseDocumentItemsFilterInput
} from '../../../../__generated__/schema'
import {useTranslateProductState} from '../../../../hooks/translateProductState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {
  GET_LIGHTWEIGHT_PRODUCT_TYPES,
  LIGHTWEIGHT_BUSINESS_PARTNERS,
  LIGHTWEIGHT_PRODUCTS
} from '../graphql'

export const DEFAULT_WAREHOUSE_DOCUMENT_ITEMS_FILTER_INPUT: WarehouseDocumentItemsFilterInput =
  {
    hasText: undefined
  }

type ExtendedWarehouseDocumentItemsFilterInput =
  WarehouseDocumentItemsFilterInput & {
    _createdAtDateRangeId?: DATERANGE_IDS
    _updatedAtDateRangeId?: DATERANGE_IDS
  }

const stripCreatedAtDateFromFilter = (
  filter: ExtendedWarehouseDocumentItemsFilterInput
): ExtendedWarehouseDocumentItemsFilterInput =>
  omit(filter, [
    'warehouseDocumentCreatedAtFrom',
    'warehouseDocumentCreatedAtTo',
    '_createdAtDateRangeId'
  ])

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedWarehouseDocumentItemsFilterInput
): ExtendedWarehouseDocumentItemsFilterInput =>
  omit(filter, [
    'warehouseDocumentUpdatedAtFrom',
    'warehouseDocumentUpdatedAtTo',
    '_updatedAtDateRangeId'
  ])

const stripHelperKeysFromFilter = (
  filter: ExtendedWarehouseDocumentItemsFilterInput
): ExtendedWarehouseDocumentItemsFilterInput =>
  omit(filter, ['_createdAtDateRangeId', '_updatedAtDateRangeId'])

const dateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.LAST_MONTH
]

const mapHasTextToFilter = (
  filter: WarehouseDocumentItemsFilterInput,
  hasText?: string
): WarehouseDocumentItemsFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapProductStateToFilter = (
  filter: WarehouseDocumentItemsFilterInput,
  state?: ProductState
): WarehouseDocumentItemsFilterInput => ({
  ...filter,
  productStates: state ? [state] : undefined
})

const mapProductIdToFilter = (
  filter: WarehouseDocumentItemsFilterInput,
  productId?: number
): WarehouseDocumentItemsFilterInput => ({
  ...filter,
  productIds: productId ? [productId] : undefined
})

const mapProductTypeIdToFilter = (
  filter: WarehouseDocumentItemsFilterInput,
  productTypeId?: number
): WarehouseDocumentItemsFilterInput => ({
  ...filter,
  productTypeIds: productTypeId ? [productTypeId] : undefined
})

const mapBusinessPartnerIdToFilter = (
  filter: WarehouseDocumentItemsFilterInput,
  businessPartnerId?: number
): WarehouseDocumentItemsFilterInput => ({
  ...filter,
  warehouseDocumentBusinessPartnerIds: businessPartnerId
    ? [businessPartnerId]
    : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateProductState = useTranslateProductState()
  const getHasTextFromSearchObject = (
    filter: WarehouseDocumentItemsFilterInput
  ) => filter.hasText || undefined
  const getProductStateFromSearchObject = (
    filter: WarehouseDocumentItemsFilterInput
  ) =>
    filter.productStates && filter.productStates.length
      ? t('Product state: {{state}}', {
          state: translateProductState(filter.productStates[0])
        })
      : undefined
  const getProductIdFromSearchObject = (
    filter: WarehouseDocumentItemsFilterInput,
    products: {id: number; name: string}[]
  ) => {
    if (filter.productIds && filter.productIds.length) {
      const productId = filter.productIds[0]
      const product = products.find(({id}) => id === productId)
      return product
        ? t('Product: {{name}}', {
            name: product.name
          })
        : t('Product: {{id}}', {id: productId})
    }
    return undefined
  }
  const getProductTypeFromSearchObject = (
    filter: WarehouseDocumentItemsFilterInput,
    productTypes: {id: number; name: string}[]
  ) => {
    if (filter.productTypeIds && filter.productTypeIds.length) {
      const productTypeId = filter.productTypeIds[0]
      const productType = productTypes.find(({id}) => id === productTypeId)
      return productType
        ? t('Product type: {{name}}', {
            name: productType.name
          })
        : t('Product type: {{id}}', {id: productTypeId})
    }
    return undefined
  }
  const getBusinessPartnerIdFromSearchObject = (
    filter: WarehouseDocumentItemsFilterInput,
    businessPartners: {id: number; companyName: string}[]
  ) => {
    if (
      filter.warehouseDocumentBusinessPartnerIds &&
      filter.warehouseDocumentBusinessPartnerIds.length
    ) {
      const businessPartnerId = filter.warehouseDocumentBusinessPartnerIds[0]
      const businessPartner = businessPartners.find(
        ({id}) => id === businessPartnerId
      )
      return businessPartner
        ? t('Business partner: {{companyName}}', {
            companyName: businessPartner.companyName
          })
        : t('Business partner ID: {{id}}', {id: businessPartnerId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getProductStateFromSearchObject,
    getProductIdFromSearchObject,
    getBusinessPartnerIdFromSearchObject,
    getProductTypeFromSearchObject
  }
}

interface IInventoryTransactionsSearchProps {
  onFilterChange: (filter: WarehouseDocumentItemsFilterInput) => void
}

export const InventoryTransactionsSearch: React.FC<IInventoryTransactionsSearchProps> =
  ({onFilterChange}: IInventoryTransactionsSearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {data: productsData} = useQuery<
      LightweightProductsQuery,
      LightweightProductsQueryVariables
    >(LIGHTWEIGHT_PRODUCTS, {
      variables: {paginationInput: {offset: 0, limit: 300}},
      fetchPolicy: 'network-only',
      skip: !P([PermissionCode.ReadProducts])
    })
    const {data: productTypesData} = useQuery<
      GetLightweightProductTypesQuery,
      GetLightweightProductTypesQueryVariables
    >(GET_LIGHTWEIGHT_PRODUCT_TYPES, {
      fetchPolicy: 'network-only',
      skip: !P([PermissionCode.ReadProductTypes])
    })
    const {data: businessPartnersData} = useQuery<
      LightweightBusinessPartnersQuery,
      LightweightBusinessPartnersQueryVariables
    >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
      variables: {paginationInput: {offset: 0, limit: 100}},
      fetchPolicy: 'network-only',
      skip: !P([PermissionCode.ReadBusinessPartners])
    })
    const {
      daterangeOptions: createdAtDateDaterangeOptions,
      mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
      mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
      getStringifiedDaterangeFromSearchObject:
        getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
    } = useDateRangeSearch<ExtendedWarehouseDocumentItemsFilterInput>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Created at'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.warehouseDocumentCreatedAtFrom || undefined,
        endDateISOString: o.warehouseDocumentCreatedAtTo || undefined,
        id: o._createdAtDateRangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        warehouseDocumentCreatedAtFrom: input.startDate,
        warehouseDocumentCreatedAtTo: input.endDate,
        _createdAtDateRangeId: input.id
      })
    })
    const {
      daterangeOptions: updatedAtDateDaterangeOptions,
      mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
      mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
      getStringifiedDaterangeFromSearchObject:
        getStringifiedUpdatedAtDateDateRangeFromSearchObject,
      getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
    } = useDateRangeSearch<ExtendedWarehouseDocumentItemsFilterInput>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Updated at'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.warehouseDocumentUpdatedAtFrom || undefined,
        endDateISOString: o.warehouseDocumentUpdatedAtTo || undefined,
        id: o._updatedAtDateRangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        warehouseDocumentUpdatedAtFrom: input.startDate,
        warehouseDocumentUpdatedAtTo: input.endDate,
        _updatedAtDateRangeId: input.id
      })
    })
    const {
      getHasTextFromSearchObject,
      getProductStateFromSearchObject,
      getProductIdFromSearchObject,
      getBusinessPartnerIdFromSearchObject,
      getProductTypeFromSearchObject
    } = useGetFieldFromSearchObject()
    const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
      getHasTextFromSearchObject,
      getProductStateFromSearchObject,
      (filter) =>
        getProductIdFromSearchObject(
          filter,
          productsData?.products.items || []
        ),
      (filter) =>
        getProductTypeFromSearchObject(
          filter,
          productTypesData?.productTypes || []
        ),
      (filter) =>
        getBusinessPartnerIdFromSearchObject(
          filter,
          businessPartnersData?.businessPartners.items || []
        ),
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getStringifiedUpdatedAtDateDateRangeFromSearchObject
    )
    const translateProductState = useTranslateProductState()
    const productSelectOptions: ISelectOption<number>[] = (
      productsData?.products.items || []
    ).map(({id, name}) => ({id, label: name}))
    const productTypeSelectOptions: ISelectOption<number>[] = (
      productTypesData?.productTypes || []
    ).map(({id, name}) => ({id, label: name}))
    const businessPartnerSelectOptions: ISelectOption<number>[] = (
      businessPartnersData?.businessPartners.items || []
    ).map(({id, companyName}) => ({id, label: companyName}))
    return (
      <Search<
        WarehouseDocumentItemsFilterInput,
        ExtendedWarehouseDocumentItemsFilterInput
      >
        storageKey="INVENTORY_TRANSACTIONS"
        placeholder={t('Search for product')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapHasTextToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={DEFAULT_WAREHOUSE_DOCUMENT_ITEMS_FILTER_INPUT}
        stripExtendedSearchObject={stripHelperKeysFromFilter}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchTextRow<WarehouseDocumentItemsFilterInput>
              label={t('Has text')}
              placeholder={t('Enter words found in product')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapHasTextToFilter}
              value={advancedSearchObject.hasText || undefined}
            />
            <AdvancedSearchSelectRow<
              WarehouseDocumentItemsFilterInput,
              ProductState
            >
              label={t('Product state')}
              value={
                advancedSearchObject.productStates
                  ? advancedSearchObject.productStates[0]
                  : undefined
              }
              options={[
                ProductState.Active,
                ProductState.Inactive,
                ProductState.Disabled
              ].map((state) => ({
                id: state,
                label: translateProductState(state)
              }))}
              mapSelectValueToSearchObject={mapProductStateToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            {P([PermissionCode.ReadProducts]) && (
              <AdvancedSearchSelectRow<
                WarehouseDocumentItemsFilterInput,
                number
              >
                label={t('Product')}
                value={
                  advancedSearchObject.productIds
                    ? advancedSearchObject.productIds[0]
                    : undefined
                }
                options={productSelectOptions}
                mapSelectValueToSearchObject={mapProductIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadProductTypes]) && (
              <AdvancedSearchSelectRow<
                WarehouseDocumentItemsFilterInput,
                number
              >
                label={t('Product type')}
                value={
                  advancedSearchObject.productTypeIds
                    ? advancedSearchObject.productTypeIds[0]
                    : undefined
                }
                options={productTypeSelectOptions}
                mapSelectValueToSearchObject={mapProductTypeIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadBusinessPartners]) && (
              <AdvancedSearchSelectRow<
                WarehouseDocumentItemsFilterInput,
                number
              >
                label={t('Business partner')}
                value={
                  advancedSearchObject.warehouseDocumentBusinessPartnerIds
                    ? advancedSearchObject
                        .warehouseDocumentBusinessPartnerIds[0]
                    : undefined
                }
                options={businessPartnerSelectOptions}
                mapSelectValueToSearchObject={mapBusinessPartnerIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            <AdvancedSearchDaterangeRow<WarehouseDocumentItemsFilterInput>
              label={t('Created at')}
              daterangeOptions={createdAtDateDaterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomCreatedAtDateRangeToFilter
              }
              mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
              getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range for warehouse document created at'
              )}
            />
            <AdvancedSearchDaterangeRow<WarehouseDocumentItemsFilterInput>
              label={t('Updated at')}
              daterangeOptions={updatedAtDateDaterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomUpdatedAtDateRangeToFilter
              }
              mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
              getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range for warehouse document updated at'
              )}
            />
          </AdvancedSearchBase>
        )}
      />
    )
  }

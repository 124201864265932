import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {ListItemIcon, Menu, MenuItem} from '@mui/material'
import {isNull} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {EventState, PermissionCode} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {useCopyEvent} from '../graphql'
import {CopyEventDialog} from './CopyEventDialog'
import {CopyEventFormField, ICopyEventForm} from './types'

type ContextMenu = {
  mouseX: number
  mouseY: number
} | null

export const useEventContextMenu = () => {
  const {P} = useEnsurePermissions()
  const [contextMenu, setContextMenu] = useState<ContextMenu>(null)
  const openContextMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      setContextMenu((prevState) =>
        isNull(prevState)
          ? {mouseX: event.clientX + 2, mouseY: event.clientY - 6}
          : null
      )
    },
    []
  )
  const closeContextMenu = useCallback(() => setContextMenu(null), [])
  const canOpenContextMenu = P([PermissionCode.CopyEvent])
  return {
    contextMenu,
    setContextMenu,
    openContextMenu,
    closeContextMenu,
    canOpenContextMenu
  }
}

interface IEventContextMenuProps {
  contextMenu: ContextMenu
  setContextMenu: (contextMenu: ContextMenu) => void
  event: {
    id: number
    title: string
    startsAt?: Date
    distributionText: string
    divisionName?: string
  }
}

export const EventContextMenu: React.FC<IEventContextMenuProps> = ({
  contextMenu,
  setContextMenu,
  event
}: IEventContextMenuProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const copyEvent = useCopyEvent()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const {
    state: isCopyEventDialogOpen,
    setTrue: openCopyEventDialog,
    setFalse: closeCopyEventDialog
  } = useBooleanState(false)
  const history = useHistory()
  const closeContextMenu = useCallback(
    () => setContextMenu(null),
    [setContextMenu]
  )
  const handleCopyEventClick = useCallback(() => {
    openCopyEventDialog()
    closeContextMenu()
  }, [closeContextMenu, openCopyEventDialog])
  const handleSubmitCopyEvent = useCallback(
    async (formData: ICopyEventForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await copyEvent({
          eventId: event.id,
          destinationDateTime:
            formData[CopyEventFormField.DestionationDateTime].toISOString(),
          state: formData[CopyEventFormField.State] || undefined
        })
        addInfoNotification(
          t('Event has been copied'),
          data && P([PermissionCode.ReadEvent])
            ? {
                label: t('Edit'),
                onClick: () =>
                  history.push(routeTo.admin.events.edit(data?.copyEvent.id))
              }
            : undefined
        )
        closeCopyEventDialog()
      } catch (error) {
        defaultErrorHandler(error, t('Error while copying event'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      P,
      addInfoNotification,
      closeCopyEventDialog,
      copyEvent,
      defaultErrorHandler,
      event.id,
      history,
      setShowBackdrop,
      t
    ]
  )
  return (
    <>
      <Menu
        open={!isNull(contextMenu)}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          isNull(contextMenu)
            ? undefined
            : {top: contextMenu.mouseY, left: contextMenu.mouseX}
        }
      >
        {P([PermissionCode.CopyEvent]) && (
          <MenuItem onClick={handleCopyEventClick}>
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            {t('Copy event')}
          </MenuItem>
        )}
      </Menu>
      <CopyEventDialog
        isOpen={isCopyEventDialogOpen}
        onClose={closeCopyEventDialog}
        onSubmit={handleSubmitCopyEvent}
        title={event.title}
        description={[
          event.startsAt &&
            [formatDateNumeric(event.startsAt), formatTime(event.startsAt)]
              .filter(Boolean)
              .join(' '),
          event.distributionText
        ]
          .filter(Boolean)
          .join(' • ')}
        divisionName={event.divisionName}
        defaultValues={{
          [CopyEventFormField.State]: EventState.Draft
        }}
      />
    </>
  )
}

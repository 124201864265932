import {Box, MenuItem, TextField, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {TFunction} from 'i18next'
import {get, omit, uniqBy} from 'lodash'
import React, {ChangeEvent, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ISeatStateValue} from '../../shared/editor/stateTypes'
import {Theme} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {ObjectsStateValue} from '../redux/objects/types'

const usePlaceholderAndValue = ({
  selectedObjects,
  iteratee
}: {
  selectedObjects: ObjectsStateValue[]
  iteratee: string
}) => {
  const {t} = useTranslation()
  const isMixed =
    selectedObjects.length > 1 && uniqBy(selectedObjects, iteratee).length > 1
  return {
    placeholder: isMixed ? t<string>('Mixed values') : undefined,
    value:
      !isMixed && selectedObjects.length > 0
        ? get(selectedObjects[0], iteratee) ?? 'available'
        : undefined
  }
}

interface IDefaultSeatStatesProps {
  className?: string
  seatStateObjects: ISeatStateValue[]
}

const getItems = (t: TFunction) => [
  {
    value: 'disabled',
    primaryText: t('Disabled'),
    secondaryText: t('Disabled description')
  },
  {
    value: 'hidden',
    primaryText: t('Hidden'),
    secondaryText: t('Hidden description')
  },
  {
    value: 'available',
    primaryText: t('Available'),
    secondaryText: t('Available description')
  }
]

const useStyles = makeStyles<Theme, {placeholder?: string}>(() => ({
  dontDisplaySecondChild: {
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  selectRoot: ({placeholder}) =>
    placeholder
      ? {
          '& span::after': {
            content: `"${placeholder}"`,
            opacity: 0.42
          }
        }
      : {}
}))

export const DefaultSeatStates: React.FC<IDefaultSeatStatesProps> = ({
  className,
  seatStateObjects
}: IDefaultSeatStatesProps) => {
  const {t} = useTranslation()
  const items = useMemo(() => getItems(t), [t])

  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects: seatStateObjects,
    iteratee: 'defaultState'
  })
  const classes = useStyles({placeholder})
  const {updateObjects} = useObjectsActions()
  return (
    <Box className={className}>
      <Typography variant="subtitle2">{t('Default seat states')}</Typography>
      <TextField
        select
        label={t('Default state')}
        InputLabelProps={{shrink: true}}
        variant="outlined"
        fullWidth
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updateObjects(
            seatStateObjects.map(
              <T extends ISeatStateValue>(object: T): T =>
                ({
                  ...omit(object, 'defaultState'),
                  ...(['disabled', 'hidden'].includes(e.target.value)
                    ? {
                        defaultState: e.target.value
                      }
                    : {})
                } as T)
            )
          )
        }}
        value={value || ''}
        SelectProps={{
          classes: {
            select: cn(
              {
                [classes.selectRoot]: placeholder
              },
              classes.dontDisplaySecondChild
            )
          }
        }}
      >
        {items.map(({value, primaryText, secondaryText}) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Typography>{primaryText}</Typography>
            <Typography variant="caption">{secondaryText}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

import {SeatGroup} from '../../types'
import {
  CREATE_SEAT_GROUP,
  DELETE_SEAT_GROUP,
  EDIT_SEAT_GROUP,
  ICreateSeatGroupAction,
  IDeleteSeatGroupAction,
  IEditSeatGroupAction,
  ISetSeatGroupsAction,
  SeatGroups,
  SET_SEAT_GROUPS
} from './reducer'

export const getSetSeatGroupsAction = (
  payload: SeatGroups
): ISetSeatGroupsAction => ({
  type: SET_SEAT_GROUPS,
  payload
})

export const getDeleteSeatGroupAction = (
  id: string
): IDeleteSeatGroupAction => ({
  type: DELETE_SEAT_GROUP,
  payload: id
})

export const getEditSeatGroupAction = (
  payload: SeatGroup
): IEditSeatGroupAction => ({
  type: EDIT_SEAT_GROUP,
  payload
})

export const getCreateSeatGroupAction = (
  payload: SeatGroup
): ICreateSeatGroupAction => ({
  type: CREATE_SEAT_GROUP,
  payload
})

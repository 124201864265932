import onScan, {ScanOptions} from 'onscan.js'
import {
  Auditorium,
  CashDeskEventDetailPropertiesFragment,
  CashDeskTourTimeSlotDetailPropertiesFragment,
  ClientShowImagePropertiesFragment,
  Event,
  Item,
  LibraryShowImagePropertiesFragment,
  ProductItemPropertiesFragment,
  TemplateType,
  TicketItem,
  TicketItemPropertiesFragment,
  TourItem,
  TourItemPropertiesFragment
} from '../../../__generated__/schema'

export type PinnedEventPick = Pick<
  Event,
  | 'id'
  | 'startsAt'
  | 'names'
  | 'formatCode'
  | 'soundMixCode'
  | 'versionCode'
  | 'ageClassificationCode'
> & {
  auditorium: Pick<Auditorium, 'id' | 'name'>
}

export const isTicketItemPropertiesFragment = (
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
): item is TicketItemPropertiesFragment => item.__typename === 'TicketItem'

export const isProductItemPropertiesFragment = (
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
): item is ProductItemPropertiesFragment => item.__typename === 'ProductItem'

export const isTourItemPropertiesFragment = (
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
): item is TourItemPropertiesFragment => item.__typename === 'TourItem'

export const isTourOrTicketItemPropertiesFragment = (
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
): item is TicketItemPropertiesFragment | TourItemPropertiesFragment =>
  item.__typename === 'TicketItem' || item.__typename === 'TourItem'

export const isCashDeskTourTimeSlotDetailPropertiesFragment = (
  item:
    | CashDeskEventDetailPropertiesFragment
    | CashDeskTourTimeSlotDetailPropertiesFragment
): item is CashDeskTourTimeSlotDetailPropertiesFragment =>
  item.__typename === 'TourTimeSlot'

export const isCashDeskEventDetailPropertiesFragment = (
  item:
    | CashDeskEventDetailPropertiesFragment
    | CashDeskTourTimeSlotDetailPropertiesFragment
): item is CashDeskEventDetailPropertiesFragment => item.__typename === 'Event'

export const isTicketItem = (
  item: Pick<Item, '__typename'>
): item is TicketItem => item.__typename === 'TicketItem'

export const isTourItem = (item: Pick<Item, '__typename'>): item is TourItem =>
  item.__typename === 'TourItem'

export const isNotProductItem = (
  item: Pick<Item, '__typename'>
): item is TourItem | TicketItem => item.__typename !== 'ProductItem'

export type GroupedEventsType<T> = {
  date: string
  events: T[]
}

export type GroupedEntitiesType<T> = {
  date: string
  entities: T[]
}

export const isClientShowImagePropertiesFragment = (
  showImage:
    | ClientShowImagePropertiesFragment
    | LibraryShowImagePropertiesFragment
): showImage is ClientShowImagePropertiesFragment =>
  showImage.__typename === 'ClientShowImage'

export const isLibraryShowImagePropertiesFragment = (
  showImage:
    | LibraryShowImagePropertiesFragment
    | ClientShowImagePropertiesFragment
): showImage is LibraryShowImagePropertiesFragment =>
  showImage.__typename === 'LibraryShowImage'

export const isTemplateType = (
  type: TemplateType | string
): type is TemplateType =>
  Object.values(TemplateType).includes(type as TemplateType)

export const keyCodeMapper: ScanOptions['keyCodeMapper'] = (oEvent) => {
  switch (oEvent.which) {
    case 48:
      return 0
    case 49:
      return 1
    case 50:
      return 2
    case 51:
      return 3
    case 52:
      return 4
    case 53:
      return 5
    case 54:
      return 6
    case 55:
      return 7
    case 56:
      return 8
    case 57:
      return 9
    default:
      return onScan.decodeKeyEvent(oEvent)
  }
}

type Only<T, U> = {
  [P in keyof T]: T[P]
} &
  {
    [P in keyof U]?: never
  }

export type Either<T, U> = Only<T, U> | Only<U, T>

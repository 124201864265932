import {FeSeatState, FeZoneState} from '@attendio/shared-components'
import {Box} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  AuditoriumPreviewFieldsFragment,
  CartPropertiesFragment,
  EventTicketType,
  Scalars,
  TicketItemPropertiesFragment
} from '../../../../__generated__/schema'
import {useGetClientLocaleTranslation} from '../../../../hooks/getClientLocaleTranslation'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {
  AuditoriumLayoutPreview,
  useFeSeatStatesByUuid,
  useFeZoneStatesByUuid,
  useLayout,
  useTicketTypesByTicketTypeId
} from '../../../common/auditoriumLayoutPreview'
import {ListOfItemsSeparatedByDividers} from '../../../common/ListOfItemsSeparatedByDividers'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {
  BasicTicketListItem,
  useGetBasicTicketListItemProps
} from '../components/BasicTicketListItem'
import {TicketsSubtotal} from '../components/TicketsSubtotal'
import {isTicketItemPropertiesFragment} from '../types'
import {Header} from './Header'
import {TicketPricesRow} from './TicketPricesRow'

const getFeSeatStateInEventDetail = (
  apiSeatState?: ApiSeatState
): FeSeatState => {
  switch (apiSeatState) {
    case ApiSeatState.Available:
      return FeSeatState.Available
    case ApiSeatState.Hidden:
      return FeSeatState.NotShown
    case ApiSeatState.SelectedByOthers:
    case ApiSeatState.SelectedByMe:
    case ApiSeatState.AddedToOtherCart:
    case ApiSeatState.InOtherReservation:
    case ApiSeatState.Sold:
    case ApiSeatState.PreSold:
      return FeSeatState.UnAvailable
    case ApiSeatState.AddedToMyCart:
    case ApiSeatState.ReservedInMyCart:
      return FeSeatState.Selected
    case ApiSeatState.Disabled:
      return FeSeatState.Disabled
    case ApiSeatState.InMyReservation:
      return FeSeatState.Reserved
    default:
      return FeSeatState.Plain
  }
}

const getFeZoneStateInEventAuditoriumPreview = (
  zoneStatesWithCounts?: {
    [keys in ApiSeatState]: number
  }
): FeZoneState => {
  if (zoneStatesWithCounts) {
    const {
      [ApiSeatState.Available]: available = 0,
      [ApiSeatState.AddedToMyCart]: addedToMyCart = 0
    } = zoneStatesWithCounts
    if (addedToMyCart > 0) {
      return FeZoneState.Selected
    }
    if (available && available > 0) {
      return FeZoneState.Available
    }
    return FeZoneState.UnAvailable
  }
  return FeZoneState.UnAvailable
}

interface IEventAuditoriumViewProps {
  event: AuditoriumPreviewFieldsFragment
  eventSeats: Scalars['JSON']
  cart: CartPropertiesFragment | null
}

const getUndefined = () => undefined

export const EventAuditoriumView: React.FC<IEventAuditoriumViewProps> = ({
  event,
  eventSeats,
  cart
}: IEventAuditoriumViewProps) => {
  const {t} = useTranslation()
  const getClientLocaleTranslation = useGetClientLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const startsAt = new Date(event.startsAt)
  const eventTicketItems = (cart?.items || [])
    .filter<TicketItemPropertiesFragment>(isTicketItemPropertiesFragment)
    .filter((item) => item.eventSeat?.event.id === event.id)
    .filter((item) => item.reservation === null)
  const getBasicTicketListItemProps = useGetBasicTicketListItemProps()
  const ticketTypesByTicketTypeId: {[id: string]: EventTicketType} =
    useTicketTypesByTicketTypeId(event)
  const layout = useLayout(
    event.auditoriumLayout.layout,
    event.auditoriumLayoutPricing.pricing,
    ticketTypesByTicketTypeId
  )
  const {seats, zones} = eventSeats
  const feSeatStatesByUuid = useFeSeatStatesByUuid({
    seats,
    mapApiSeatStateToFeSeatState: getFeSeatStateInEventDetail
  })

  const feZoneStatesByUuid = useFeZoneStatesByUuid({
    zones,
    mapZoneApiSeatStatesToFeZoneState: getFeZoneStateInEventAuditoriumPreview
  })
  const lastElementRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [eventTicketItems])
  return (
    <PageWithHeaderTemplate
      header={
        <Header
          title={[
            formatDateNumeric(startsAt),
            formatTime(startsAt),
            getClientLocaleTranslation(event.names)
          ].join(' • ')}
        />
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
            "subCart subHeader"
            "subCart auditoriumLayoutPreview"
          `,
          gridTemplateRows: 'auto 1fr',
          gridTemplateColumns: '360px 1fr',
          height: '100%'
        }}
      >
        <TicketPricesRow
          event={event}
          sx={{
            gridArea: 'subHeader',
            px: 3,
            py: 1
          }}
        />
        <Box
          sx={{
            gridArea: 'subCart',
            backgroundColor: `background.paper`,
            borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
            display: 'grid',
            gridTemplateRows: '1fr auto'
          }}
        >
          <ListOfItemsSeparatedByDividers sx={{px: 2}}>
            {eventTicketItems.map((ticketItem, index) => (
              <Box
                key={ticketItem.id}
                sx={{
                  display: 'grid',
                  minHeight: 56,
                  gridTemplateColumns: 'auto 1fr auto',
                  alignItems: 'center'
                }}
                ref={
                  index === eventTicketItems.length - 1 ? lastElementRef : null
                }
              >
                <BasicTicketListItem
                  {...getBasicTicketListItemProps(ticketItem)}
                />
              </Box>
            ))}
          </ListOfItemsSeparatedByDividers>
          <TicketsSubtotal
            items={eventTicketItems}
            description={t('{{count}} ticket', {
              count: eventTicketItems.length
            })}
          />
        </Box>
        <AuditoriumLayoutPreview
          sx={{
            gridArea: 'auditoriumLayoutPreview'
          }}
          layout={layout}
          eventSeats={eventSeats}
          feSeatStatesByUuid={feSeatStatesByUuid}
          feZoneStatesByUuid={feZoneStatesByUuid}
          TooltipProps={null}
          getLayoutObjectMouseEnterHandler={getUndefined}
          getLayoutObjectMouseLeaveHandler={getUndefined}
          getSeatClickHandler={getUndefined}
          getZoneClickHandler={getUndefined}
          isZoomPanelHidden
        />
      </Box>
    </PageWithHeaderTemplate>
  )
}

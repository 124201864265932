import {useCallback, useMemo} from 'react'
import {useDispatch, useStore} from 'react-redux'

import {DisplayMode} from '../displayMode/reducer'
import {displayModeSelector} from '../displayMode/selectors'
import {isCtrlActiveSelector} from '../keyboardKeys/selectors'
import {
  objectsIdsSelector,
  priceAssignableObjectsIdsSelector
} from '../objects/selectors'
import {ObjectId, SelectionActionType} from './reducer'
import {selectedObjectsIdsSelector} from './selectors'

export const unselectAllAction = {type: SelectionActionType.UNSELECT_ALL}

export const useSelectionActions = () => {
  const dispatch = useDispatch()
  const store = useStore()

  const selectObject = useCallback(
    (id: ObjectId) => {
      dispatch({type: SelectionActionType.SELECT_SINGLE, payload: id})
    },
    [dispatch]
  )

  const addObjectsToSelection = useCallback(
    (ids: Array<ObjectId>) => {
      dispatch({
        type: SelectionActionType.ADD_OBJECTS_TO_SELECTION,
        payload: ids
      })
    },
    [dispatch]
  )

  const unselectObject = useCallback(
    (id: ObjectId) => {
      dispatch({type: SelectionActionType.UNSELECT_SINGLE, payload: id})
    },
    [dispatch]
  )

  const selectAll = useCallback(() => {
    const state = store.getState()
    const displayMode = displayModeSelector(state)
    const objectsIds =
      displayMode === DisplayMode.PRICING
        ? priceAssignableObjectsIdsSelector(state)
        : objectsIdsSelector(state)
    dispatch({type: SelectionActionType.SELECT_MULTIPLE, payload: objectsIds})
  }, [dispatch, store])

  /**
   * @deprecated - use unselectAllAction
   * @see unselectAllAction
   */
  const unselectAll = useCallback(() => {
    dispatch(unselectAllAction)
  }, [dispatch])

  const selectMultiple = useCallback(
    (ids: Array<ObjectId>) => {
      const state = store.getState()
      const currentIds = selectedObjectsIdsSelector(store.getState())
      if (currentIds.length === 0 && ids.length === 0) return
      const isCtrlPressed = isCtrlActiveSelector(state)
      isCtrlPressed
        ? dispatch({
            type: SelectionActionType.ADD_OBJECTS_TO_SELECTION,
            payload: ids
          })
        : dispatch({type: SelectionActionType.SELECT_MULTIPLE, payload: ids})
    },
    [dispatch, store]
  )

  const handleSingleObjectSelection = useCallback(
    (id: ObjectId, forceCtrlMode: boolean = false) => {
      const state = store.getState()
      const selectedObjectsIds = selectedObjectsIdsSelector(state)
      const isCtrlPressed = forceCtrlMode || isCtrlActiveSelector(state)
      if (selectedObjectsIds.includes(id)) {
        isCtrlPressed ? unselectObject(id) : selectObject(id)
      } else {
        isCtrlPressed ? addObjectsToSelection([id]) : selectObject(id)
      }
    },
    [addObjectsToSelection, selectObject, store, unselectObject]
  )

  const setDoubleClick = useCallback(
    (doubleClick: boolean) => {
      dispatch({
        type: SelectionActionType.SET_DOUBLE_CLICK,
        payload: doubleClick
      })
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      selectObject,
      unselectObject,
      addObjectsToSelection,
      handleSingleObjectSelection,
      selectAll,
      unselectAll,
      selectMultiple,
      setDoubleClick
    }),
    [
      selectObject,
      unselectObject,
      addObjectsToSelection,
      handleSingleObjectSelection,
      selectAll,
      unselectAll,
      selectMultiple,
      setDoubleClick
    ]
  )

  return actions
}

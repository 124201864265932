export enum DisplayMode {
  /**
   * For example editor of draft auditorium layouts
   */
  FULL = 'full',
  FULL_WITHOUT_SEATS = 'full without seats',
  PRICING = 'pricing',
  CASH = 'cash',
  /**
   * For example editor of active auditorium layouts
   */
  RESTRICTED = 'restricted'
}

export type DisplayModeState = DisplayMode

const initialState: DisplayModeState = DisplayMode.FULL

export const SET_DISPLAY_MODE = 'SET_DISPLAY_MODE'

interface ISetDisplayModeAction {
  type: typeof SET_DISPLAY_MODE
  payload: DisplayMode
}

type DisplayModeAction = ISetDisplayModeAction

export const displayModeReducer = (
  state = initialState as DisplayModeState,
  action: DisplayModeAction
): DisplayModeState => {
  switch (action.type) {
    case SET_DISPLAY_MODE:
      return action.payload
    default:
      return state
  }
}

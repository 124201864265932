import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Box, IconButton, Paper, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {batch, useDispatch} from 'react-redux'
import {TreatGroupSeatsOptions} from '../../../../../../__generated__/schema'
import {getRemoveBadgesFromSeatsAction} from '../../../../../../editor/redux/objects/actions'
import {
  getDeleteSeatGroupAction,
  getEditSeatGroupAction
} from '../../../../../../editor/redux/seatGroups/actions'
import {SeatGroup} from '../../../../../../editor/types'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {EditSeatGroupDialog} from './EditSeatGroupDialog'

interface ISeatGroupItemProps {
  seatGroup: SeatGroup
  onDeleteButtonClick?: () => void
  onEditButtonClick?: () => void
}

const getSecondaryText = ({
  retailTreatOptions,
  t,
  eCommerceTreatOptions
}: {
  retailTreatOptions: TreatGroupSeatsOptions
  t: TFunction
  eCommerceTreatOptions: TreatGroupSeatsOptions
}) =>
  retailTreatOptions === TreatGroupSeatsOptions.AsGroups
    ? eCommerceTreatOptions === TreatGroupSeatsOptions.AsGroups
      ? t('Select as group: All channels')
      : t('Select as group: Retail')
    : eCommerceTreatOptions === TreatGroupSeatsOptions.AsGroups
    ? t('Select as group: Ecommerce')
    : t('Select as group: No')

const SeatGroupItem = ({
  seatGroup,
  onDeleteButtonClick,
  onEditButtonClick
}: ISeatGroupItemProps) => {
  const {t} = useTranslation()
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'grid',
        gridTemplateAreas: `
          "edit   primaryText    delete"
          "edit   secondaryText  delete"
        `,
        gridTemplateColumns: 'auto 1fr auto',
        px: 2,
        py: 1
      }}
    >
      <IconButton
        color="primary"
        sx={{gridArea: 'edit'}}
        edge="start"
        onClick={onEditButtonClick}
      >
        <EditIcon />
      </IconButton>
      <Typography
        sx={{gridArea: 'primaryText', alignSelf: 'end'}}
        variant="subtitle2"
      >
        {[seatGroup.id, seatGroup.name].filter(Boolean).join(' • ')}
      </Typography>
      <Typography
        sx={{gridArea: 'secondaryText', alignSelf: 'start'}}
        variant="caption"
        color="textSecondary"
      >
        {getSecondaryText({
          t,
          eCommerceTreatOptions: seatGroup.eCommerceTreatOptions,
          retailTreatOptions: seatGroup.retailTreatOptions
        })}
      </Typography>
      <IconButton
        color="primary"
        sx={{gridArea: 'delete'}}
        edge="end"
        onClick={onDeleteButtonClick}
      >
        <DeleteIcon />
      </IconButton>
    </Paper>
  )
}

interface ISeatGroupsSectionProps {
  seatGroups: Array<SeatGroup>
}

export const SeatGroupsSection: React.FC<ISeatGroupsSectionProps> = ({
  seatGroups
}: ISeatGroupsSectionProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [deleteGroup, setDeleteGroup] =
    useState<null | {groupId: string; seatUuids: string[]}>(null)
  const getDeleteSeatGroupHandler = useCallback(
    (groupId: string, seatUuids: string[]) => () => {
      setDeleteGroup({
        groupId,
        seatUuids
      })
    },
    []
  )
  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteGroup(null)
  }, [])
  const handleConfirmDeleteClick = useCallback(() => {
    if (deleteGroup) {
      batch(() => {
        dispatch(getDeleteSeatGroupAction(deleteGroup.groupId))
        dispatch(getRemoveBadgesFromSeatsAction(deleteGroup.seatUuids))
      })
      setDeleteGroup(null)
    }
  }, [deleteGroup, dispatch])

  const [editSeatGroup, setEditSeatGroup] =
    useState<null | {isOpen: boolean; seatGroup: SeatGroup}>(null)
  const handleCloseEditDialog = useCallback(() => {
    setEditSeatGroup((editSeatGroupState) =>
      editSeatGroupState ? {...editSeatGroupState, isOpen: false} : null
    )
  }, [])
  const getEditButtonClickHandler = useCallback(
    (seatGroup: SeatGroup) => () => {
      setEditSeatGroup({
        isOpen: true,
        seatGroup
      })
    },
    []
  )
  const handleConfirmEdit = useCallback(
    (seatGroup) => {
      dispatch(getEditSeatGroupAction(seatGroup))
      handleCloseEditDialog()
    },
    [dispatch, handleCloseEditDialog]
  )
  return (
    <>
      <Typography sx={{pb: 1}} variant="subtitle1">
        {t('Seat groups')}
      </Typography>
      <Box sx={{gap: 1, display: 'flex', flexDirection: 'column'}}>
        {seatGroups.map((item) => (
          <SeatGroupItem
            seatGroup={item}
            key={item.id}
            onDeleteButtonClick={getDeleteSeatGroupHandler(
              item.id,
              item.seatUuids
            )}
            onEditButtonClick={getEditButtonClickHandler(item)}
          />
        ))}
      </Box>
      <ConfirmationDialog
        isOpen={Boolean(deleteGroup)}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleConfirmDeleteClick}
        title={t('Delete seats group?')}
        contentText={t(
          'Are you sure you want to delete this seats group? This action is irreversible and will remove the group from all associated events.'
        )}
        confirmButtonLabel={t('Delete')}
      />
      {editSeatGroup ? (
        <EditSeatGroupDialog
          onClose={handleCloseEditDialog}
          {...editSeatGroup}
          onConfirmButtonClick={handleConfirmEdit}
        />
      ) : null}
    </>
  )
}

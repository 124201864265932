import {ApolloError} from 'apollo-client'
import axios, {AxiosRequestConfig} from 'axios'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../__generated__/schema'
import {getPosTerminalAppPort} from '../components/pages/admin/cashDesk/cart/posTerminalAppUtils'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {PosTerminalApiConfigResponse, PosTerminalCurrency} from '../types'

export function getTerminalConfig<D = any>({
  port,
  ...config
}: AxiosRequestConfig<D> & {
  port: number
}): AxiosRequestConfig<D> {
  return {
    baseURL: `http://127.0.0.1:${port}/`,
    timeout: 30000,
    timeoutErrorMessage: 'Request timed out',
    ...config
  }
}

export const getIsPosTerminalAppConnectionValid = async () => {
  const port = getPosTerminalAppPort()
  if (!port) {
    return false
  }
  try {
    await axios.request<PosTerminalApiConfigResponse>(
      getTerminalConfig({
        url: '/config',
        port
      })
    )
    return true
  } catch (e) {
    return false
  }
}

export const useShowPosTerminalAppSettingsError = () => {
  const {t} = useTranslation()
  const {customErrorHandler} = useMutationAssistanceHooks()
  return useCallback(
    (
      error = new ApolloError({
        errorMessage: "Can't connect to the POS terminal app"
      })
    ) => {
      customErrorHandler(error, {
        title: t("Can't connect to the POS terminal app"),
        contentText: t(
          'We were unable to establish connection to the POS terminal app. Please check your device settings'
        ),
        confirmButtonLabel: t('Got it')
      })
    },
    [customErrorHandler, t]
  )
}

export const getPosTerminalCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.Czk:
      return PosTerminalCurrency.CZK
    case Currency.Usd:
      return PosTerminalCurrency.USD
    case Currency.Gbp:
      return PosTerminalCurrency.GBP
    case Currency.Rub:
      return PosTerminalCurrency.RUB
    case Currency.Eur:
    default:
      return PosTerminalCurrency.EUR
  }
}

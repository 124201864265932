import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TreatGroupSeatsOptions} from '../../../../../../__generated__/schema'
import {SeatGroup} from '../../../../../../editor/types'
import {useTranslateTreatGroupSeatsOptions} from '../../../../../../hooks/translateTreatGroupSeatsOption'
import {SaveButton} from '../../../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {RadioGroupItem} from '../../../../../common/RadioGroupItem'

interface IEditSeatGroupDialogProps {
  seatGroup: SeatGroup
  onClose: () => void
  isOpen: boolean
  onConfirmButtonClick: (seatGroup: SeatGroup) => void
}

export const EditSeatGroupDialog: React.FC<IEditSeatGroupDialogProps> = ({
  seatGroup,
  isOpen,
  onClose,
  onConfirmButtonClick
}: IEditSeatGroupDialogProps) => {
  const {t} = useTranslation()
  const [seatGroupState, setSeatGroupState] = useState<SeatGroup>(seatGroup)
  useEffect(() => {
    setSeatGroupState(seatGroup)
  }, [seatGroup])
  const translateTreatGroupSeatsOptions = useTranslateTreatGroupSeatsOptions()
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit seat group • {{name}}', {name: seatGroup.id})}
      </DialogTitleWithCloseButton>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle2" sx={{pb: 1}}>
          {t('Behaviour for customer on ecommerce')}
        </Typography>
        <RadioGroup
          onChange={(_event, value) =>
            setSeatGroupState((s) => ({
              ...s,
              eCommerceTreatOptions: value as TreatGroupSeatsOptions
            }))
          }
          value={seatGroupState.eCommerceTreatOptions}
          sx={{pb: 2}}
        >
          {[
            TreatGroupSeatsOptions.AsGroups,
            TreatGroupSeatsOptions.Separately
          ].map((option) => (
            <RadioGroupItem
              key={option}
              value={option}
              label={translateTreatGroupSeatsOptions(option)}
              subLabel={''}
              selectedValue={seatGroupState.eCommerceTreatOptions}
            />
          ))}
        </RadioGroup>

        <Typography variant="subtitle2" sx={{pb: 1}}>
          {t('Behaviour for cashier on retail')}
        </Typography>
        <RadioGroup
          onChange={(_event, value) =>
            setSeatGroupState((s) => ({
              ...s,
              retailTreatOptions: value as TreatGroupSeatsOptions
            }))
          }
          value={seatGroupState.retailTreatOptions}
          sx={{pb: 2}}
        >
          {[
            TreatGroupSeatsOptions.AsGroups,
            TreatGroupSeatsOptions.Separately
          ].map((option) => (
            <RadioGroupItem
              key={option}
              value={option}
              label={translateTreatGroupSeatsOptions(option)}
              subLabel={''}
              selectedValue={seatGroupState.retailTreatOptions}
            />
          ))}
        </RadioGroup>
        <TextField
          label={t('Internal name')}
          helperText={t("Customer nor cashier won't see this.")}
          fullWidth
          value={seatGroupState.name ?? ''}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSeatGroupState((s) => ({...s, name: event.target.value}))
          }}
        />
        <Typography variant="body1" sx={{pt: 2}} color="textSecondary">
          {t(
            'Caution! Any updates will immediately apply to all associated events.'
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <SaveButton
          onClick={() => {
            onConfirmButtonClick(seatGroupState)
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

import Decimal from 'decimal.js'
import {isNil} from 'lodash'
import {
  PaymentMethodPropertiesFragment,
  PaymentMethodType
} from '../../../../__generated__/schema'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {IPosTerminalSettings} from '../../../../types'
import {
  ClaimTab,
  ECOMMERCE_REFUND_FORM_ID,
  RETAIL_REFUND_FORM_ID,
  VOUCHER_REFUND_FORM_ID
} from './types'

export const getAmountAndCount = (
  paymentMethodAmount: number,
  paymentMethod?: PaymentMethodPropertiesFragment
) => {
  if (paymentMethod && paymentMethod.type === PaymentMethodType.Voucher) {
    return paymentMethod.hasDenomination
      ? {
          denomination: paymentMethod.value,
          count: paymentMethodAmount / paymentMethod.value
        }
      : {denomination: paymentMethodAmount, count: 1}
  }
  return {denomination: null, count: null}
}

export const getDefaultTab = ({
  hasRefundOnRetailPermission,
  hasRefundOnECommercePermission,
  hasRefundByVoucherPermission
}: {
  hasRefundOnRetailPermission: boolean
  hasRefundOnECommercePermission: boolean
  hasRefundByVoucherPermission: boolean
}) => {
  if (hasRefundOnRetailPermission) {
    return ClaimTab.Retail
  } else if (hasRefundOnECommercePermission) {
    return ClaimTab.Ecommerce
  } else if (hasRefundByVoucherPermission) {
    return ClaimTab.Voucher
  } else {
    return hasRefundOnRetailPermission ? ClaimTab.Retail : ClaimTab.Ecommerce
  }
}

export const getFormId = (tab: ClaimTab) => {
  switch (tab) {
    case ClaimTab.Retail:
      return RETAIL_REFUND_FORM_ID
    case ClaimTab.Ecommerce:
      return ECOMMERCE_REFUND_FORM_ID
    case ClaimTab.Voucher:
      return VOUCHER_REFUND_FORM_ID
    default:
      return ''
  }
}

export const getSurplus = (
  claimPrice: number,
  paymentMethodValue: number
): number => new Decimal(paymentMethodValue).minus(claimPrice).toNumber()

export const getPaymentMethodValue = ({
  tab,
  retailTotal,
  ecommerceTotal,
  voucherTotal,
  isSelectedVoucherActivationAndCampaign
}: {
  tab: ClaimTab
  retailTotal: number
  ecommerceTotal: number
  voucherTotal: number
  isSelectedVoucherActivationAndCampaign: boolean
}) => {
  switch (tab) {
    case ClaimTab.Retail:
      return retailTotal
    case ClaimTab.Ecommerce:
      return ecommerceTotal
    case ClaimTab.Voucher:
      return isSelectedVoucherActivationAndCampaign ? voucherTotal : 0
    default:
      return 0
  }
}

export const useGetPosTerminalState = () => {
  const [posTerminal] = useLocalStorageState<IPosTerminalSettings>(
    LocalStorageKey.PosTerminal,
    {
      enabled: false,
      port: null,
      paymentMethodId: null
    }
  )
  const isEnabled =
    posTerminal.enabled &&
    !isNil(posTerminal.port) &&
    !isNil(posTerminal.paymentMethodId)
  return {
    isEnabled,
    port: posTerminal.port,
    paymentMethodId: posTerminal.paymentMethodId
  }
}

import {
  Box,
  Button,
  RadioGroup,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import {compact, uniq} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {batch, useDispatch} from 'react-redux'
import {TreatGroupSeatsOptions} from '../../__generated__/schema'
import {RadioGroupItem} from '../../components/common/RadioGroupItem'
import {useTranslateTreatGroupSeatsOptions} from '../../hooks/translateTreatGroupSeatsOption'
import {ISeatStateValue} from '../../shared/editor/stateTypes'
import {useSelector} from '../redux'
import {
  getAddBadgesToSeatsAction,
  getRemoveBadgesFromSeatsAction
} from '../redux/objects/actions'
import {
  getCreateSeatGroupAction,
  getDeleteSeatGroupAction,
  getEditSeatGroupAction
} from '../redux/seatGroups/actions'
import {seatGroupsSelector} from '../redux/seatGroups/selectors'
import {unselectAllAction} from '../redux/selection/actions'
import {SeatGroup} from '../types'
import {
  getSeatGroupCompressedId,
  resetSeatGroupGenerator,
  restoreSeatGroupGenerator
} from '../utils/compressedIdGenerator'

enum SectionState {
  MixedGroups = 'mixedGroups',
  SameGroup = 'sameGroup',
  NoGroups = 'noGroups'
}

const getSectionState = (seatStateObjects: ISeatStateValue[]): SectionState => {
  const groupIds: (string | undefined)[] = seatStateObjects.map(
    ({badge}) => badge?.text
  )
  if (compact(groupIds).length === 0) {
    return SectionState.NoGroups
  }
  if (uniq(groupIds).length > 1) {
    return SectionState.MixedGroups
  }
  if (uniq(groupIds).length === 1) {
    return SectionState.SameGroup
  }
  return SectionState.NoGroups
}

interface ICreateSeatGroupProps {
  seatUuids: string[]
}

const CreateSeatGroup: React.FC<ICreateSeatGroupProps> = ({
  seatUuids
}: ICreateSeatGroupProps) => {
  const {t} = useTranslation()
  const [seatGroupState, setSeatGroupState] = useState<Omit<SeatGroup, 'id'>>({
    seatUuids,
    name: '',
    retailTreatOptions: TreatGroupSeatsOptions.Separately,
    eCommerceTreatOptions: TreatGroupSeatsOptions.AsGroups
  })
  const translateTreatGroupSeatsOptions = useTranslateTreatGroupSeatsOptions()
  const dispatch = useDispatch()
  const theme = useTheme()
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="caption" color="textSecondary">
        {t(
          'Seats group allows you to create predefined clusters of seats that can be sold together as a unit, perfect for scenarios like loveseats in cinemas, lodges in theaters, skyboxes or suites in stadiums, and tables at galas or comedy shows. You can define how each seat group behaves in both retail and e-commerce settings, deciding whether the seats are sold individually or as an entire group.'
        )}
      </Typography>
      <Typography variant="subtitle2" sx={{pb: 1, pt: 3}}>
        {t('Behaviour for customer on ecommerce')}
      </Typography>
      <RadioGroup
        onChange={(_event, value) =>
          setSeatGroupState((s) => ({
            ...s,
            eCommerceTreatOptions: value as TreatGroupSeatsOptions
          }))
        }
        value={seatGroupState.eCommerceTreatOptions}
        sx={{pb: 2}}
      >
        {[
          TreatGroupSeatsOptions.AsGroups,
          TreatGroupSeatsOptions.Separately
        ].map((option) => (
          <RadioGroupItem
            key={option}
            value={option}
            label={translateTreatGroupSeatsOptions(option)}
            subLabel={''}
            selectedValue={seatGroupState.eCommerceTreatOptions}
          />
        ))}
      </RadioGroup>

      <Typography variant="subtitle2" sx={{pb: 1, pt: 1}}>
        {t('Behaviour for cashier on retail')}
      </Typography>
      <RadioGroup
        onChange={(_event, value) =>
          setSeatGroupState((s) => ({
            ...s,
            retailTreatOptions: value as TreatGroupSeatsOptions
          }))
        }
        value={seatGroupState.retailTreatOptions}
        sx={{pb: 2}}
      >
        {[
          TreatGroupSeatsOptions.AsGroups,
          TreatGroupSeatsOptions.Separately
        ].map((option) => (
          <RadioGroupItem
            key={option}
            value={option}
            label={translateTreatGroupSeatsOptions(option)}
            subLabel={''}
            selectedValue={seatGroupState.retailTreatOptions}
          />
        ))}
      </RadioGroup>
      <Box sx={{pt: 1}}>
        <TextField
          label={t('Internal name')}
          helperText={t(
            "Customer nor cashier won't see this. Used only in editor."
          )}
          fullWidth
          value={seatGroupState.name ?? ''}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSeatGroupState((s) => ({...s, name: event.target.value}))
          }}
        />
      </Box>
      <Button
        variant="text"
        color="primary"
        sx={{alignSelf: 'flex-end'}}
        onClick={() => {
          batch(() => {
            const id = getSeatGroupCompressedId()
            dispatch(getCreateSeatGroupAction({...seatGroupState, id}))
            dispatch(
              getAddBadgesToSeatsAction(seatGroupState.seatUuids, {
                text: id,
                backgroundColor: theme.palette.primary.main
              })
            )
            dispatch(unselectAllAction)
          })
        }}
      >
        {t('Create group')}
      </Button>
    </Box>
  )
}

interface IEditSeatGroupProps {
  originalSeatGroup: SeatGroup
}

const EditSeatGroup: React.FC<IEditSeatGroupProps> = ({
  originalSeatGroup
}: IEditSeatGroupProps) => {
  const {t} = useTranslation()
  const [seatGroupState, setSeatGroupState] =
    useState<SeatGroup>(originalSeatGroup)
  const translateTreatGroupSeatsOptions = useTranslateTreatGroupSeatsOptions()
  const dispatch = useDispatch()
  useEffect(() => {
    setSeatGroupState(originalSeatGroup)
  }, [originalSeatGroup])
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="caption" color="textSecondary">
        {t(
          'Seats group allows you to create predefined clusters of seats that can be sold together as a unit, perfect for scenarios like loveseats in cinemas, lodges in theaters, skyboxes or suites in stadiums, and tables at galas or comedy shows. You can define how each seat group behaves in both retail and e-commerce settings, deciding whether the seats are sold individually or as an entire group.'
        )}
      </Typography>
      <Typography variant="subtitle2" sx={{pb: 1, pt: 3}}>
        {t('Behaviour for customer on ecommerce')}
      </Typography>
      <RadioGroup
        onChange={(_event, value) =>
          setSeatGroupState((s) => ({
            ...s,
            eCommerceTreatOptions: value as TreatGroupSeatsOptions
          }))
        }
        value={seatGroupState.eCommerceTreatOptions}
        sx={{pb: 2}}
      >
        {[
          TreatGroupSeatsOptions.AsGroups,
          TreatGroupSeatsOptions.Separately
        ].map((option) => (
          <RadioGroupItem
            key={option}
            value={option}
            label={translateTreatGroupSeatsOptions(option)}
            subLabel={''}
            selectedValue={seatGroupState.eCommerceTreatOptions}
          />
        ))}
      </RadioGroup>

      <Typography variant="subtitle2" sx={{pb: 1, pt: 1}}>
        {t('Behaviour for cashier on retail')}
      </Typography>
      <RadioGroup
        onChange={(_event, value) =>
          setSeatGroupState((s) => ({
            ...s,
            retailTreatOptions: value as TreatGroupSeatsOptions
          }))
        }
        value={seatGroupState.retailTreatOptions}
        sx={{pb: 2}}
      >
        {[
          TreatGroupSeatsOptions.AsGroups,
          TreatGroupSeatsOptions.Separately
        ].map((option) => (
          <RadioGroupItem
            key={option}
            value={option}
            label={translateTreatGroupSeatsOptions(option)}
            subLabel={''}
            selectedValue={seatGroupState.retailTreatOptions}
          />
        ))}
      </RadioGroup>
      <Box sx={{pt: 1}}>
        <TextField
          label={t('Internal name')}
          helperText={t(
            "Customer nor cashier won't see this. Used only in editor."
          )}
          fullWidth
          value={seatGroupState.name ?? ''}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSeatGroupState((s) => ({...s, name: event.target.value}))
          }}
        />
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          variant="text"
          color="primary"
          sx={{alignSelf: 'flex-end'}}
          onClick={() => {
            batch(() => {
              dispatch(getDeleteSeatGroupAction(seatGroupState.id))
              dispatch(getRemoveBadgesFromSeatsAction(seatGroupState.seatUuids))
              dispatch(unselectAllAction)
            })
          }}
        >
          {t('Delete')}
        </Button>
        <Button
          variant="text"
          color="primary"
          sx={{alignSelf: 'flex-end'}}
          onClick={() => {
            batch(() => {
              dispatch(getEditSeatGroupAction(seatGroupState))
              dispatch(unselectAllAction)
            })
          }}
        >
          {t('Save')}
        </Button>
      </Box>
    </Box>
  )
}

interface ISectionFrameProps {
  children: React.ReactNode
  title: string
  className?: string
}

const SectionFrame: React.FC<ISectionFrameProps> = ({
  children,
  title,
  className
}: ISectionFrameProps) => {
  return (
    <Box className={className}>
      <Typography variant="subtitle1">{title}</Typography>
      {children}
    </Box>
  )
}

interface ISeatsGroupSectionProps {
  className?: string
  seatStateObjects: ISeatStateValue[]
}

export const SeatsGroupSection: React.FC<ISeatsGroupSectionProps> = ({
  className,
  seatStateObjects
}: ISeatsGroupSectionProps) => {
  const {t} = useTranslation()
  const seatGroups = useSelector(seatGroupsSelector)
  const sectionState = getSectionState(seatStateObjects)
  useEffect(() => {
    const keys = Object.keys(seatGroups)
    if (keys.length === 0) {
      resetSeatGroupGenerator()
    } else {
      restoreSeatGroupGenerator(keys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (sectionState === SectionState.SameGroup) {
    const groupId = seatStateObjects[0].badge?.text as string
    return (
      <SectionFrame
        title={t('Seats group • {{id}}', {id: groupId})}
        className={className}
      >
        <EditSeatGroup originalSeatGroup={seatGroups[groupId]} />
      </SectionFrame>
    )
  }
  if (sectionState === SectionState.MixedGroups) {
    return (
      <SectionFrame title={t('Seats group')} className={className}>
        <Typography variant="caption" color="textSecondary">
          {t(
            'The seats group cannot be created because some of the selected seats are already part of an existing group. Please choose different seats or adjust the existing group to include your desired seats.'
          )}
        </Typography>
      </SectionFrame>
    )
  }
  // sectionState === SectionState.NoGroups
  return (
    <SectionFrame title={t('Seats group')} className={className}>
      <CreateSeatGroup
        seatUuids={seatStateObjects.map(({config}) => config.id)}
      />
    </SectionFrame>
  )
}

import {useTranslation} from 'react-i18next'
import {TreatGroupSeatsOptions} from '../__generated__/schema'

export const useTranslateTreatGroupSeatsOptions = () => {
  const {t} = useTranslation()
  const translated: Record<TreatGroupSeatsOptions, string> = {
    [TreatGroupSeatsOptions.AsGroups]: t('TreatGroupSeatsOptions->AsGroups'),
    [TreatGroupSeatsOptions.Separately]: t('TreatGroupSeatsOptions->Separately')
  }
  return (state: TreatGroupSeatsOptions) => translated[state]
}

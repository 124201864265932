import {useLazyQuery} from '@apollo/react-hooks'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LeadEventOrigin,
  LeadsQuery,
  LeadsQueryVariables,
  LeadTourTimeSlotOrigin,
  SellingChannel
} from '../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../hooks/state'
import {CreateMessageDrawer} from '../../pages/admin/components/messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../../pages/admin/components/messageDrawer/types'
import {Either} from '../../pages/admin/types'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'
import {GET_LEADS} from './graphql'

enum ToggleButtonChannel {
  All = 'all',
  Retail = 'retail',
  Ecommerce = 'ecommerce'
}

enum ToggleButtonOrderType {
  All = 'all',
  Sales = 'sales',
  Reservations = 'reservations'
}

const useTranslateToggleButtons = () => {
  const {t} = useTranslation()
  const translatedChannels: Record<ToggleButtonChannel, string> = {
    [ToggleButtonChannel.All]: t<string>('MessageDialogChannel->All'),
    [ToggleButtonChannel.Retail]: t<string>('MessageDialogChannel->Retail'),
    [ToggleButtonChannel.Ecommerce]: t<string>(
      'MessageDialogChannel->Ecommerce'
    )
  }
  const translatedOrderTypes: Record<ToggleButtonOrderType, string> = {
    [ToggleButtonOrderType.All]: t<string>('MessageDialogOrderType->All'),
    [ToggleButtonOrderType.Sales]: t<string>('MessageDialogOrderType->Sales'),
    [ToggleButtonOrderType.Reservations]: t<string>(
      'MessageDialogOrderType->Reservations'
    )
  }
  const translateChannel = (channel: ToggleButtonChannel) =>
    translatedChannels[channel]
  const translateOrderType = (orderType: ToggleButtonOrderType) =>
    translatedOrderTypes[orderType]
  return {translateChannel, translateOrderType}
}

const getFilterSellingChannel = (option: ToggleButtonChannel) => {
  switch (option) {
    case ToggleButtonChannel.Retail:
      return SellingChannel.Retail
    case ToggleButtonChannel.Ecommerce:
      return SellingChannel.ECommerce
    case ToggleButtonChannel.All:
    default:
      return undefined
  }
}

const getFilterLeadEventOrigin = (option: ToggleButtonOrderType) => {
  switch (option) {
    case ToggleButtonOrderType.Sales:
      return LeadEventOrigin.Sale
    case ToggleButtonOrderType.Reservations:
      return LeadEventOrigin.Reservation
    case ToggleButtonOrderType.All:
    default:
      return undefined
  }
}

const getLeadTourTimeSlotOrigin = (option: ToggleButtonOrderType) => {
  switch (option) {
    case ToggleButtonOrderType.Sales:
      return LeadTourTimeSlotOrigin.Sale
    case ToggleButtonOrderType.Reservations:
      return LeadTourTimeSlotOrigin.Reservation
    case ToggleButtonOrderType.All:
    default:
      return undefined
  }
}

type CreateMessageDialogProps = {
  isOpen: boolean
  onClose: () => void
} & Either<{eventId: number}, {tourTimeSlotId: number}>

export const CreateMessageDialog: React.FC<CreateMessageDialogProps> = ({
  eventId,
  tourTimeSlotId,
  isOpen,
  onClose
}: CreateMessageDialogProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {translateChannel, translateOrderType} = useTranslateToggleButtons()
  const [leads, setLeads] = useState<LeadsQuery['leads']>([])
  const [channel, setChannel] = useState<ToggleButtonChannel>(
    ToggleButtonChannel.All
  )
  const [orderType, setOrderType] = useState<ToggleButtonOrderType>(
    ToggleButtonOrderType.All
  )
  const [skip, setSkip] = useState(!isOpen)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const [getLeads, {loading: isLeadsLoading}] = useLazyQuery<
    LeadsQuery,
    LeadsQueryVariables
  >(GET_LEADS, {
    variables: {
      filter: eventId
        ? {
            eventFilter: {
              eventIds: [eventId],
              sellingChannel: getFilterSellingChannel(channel),
              leadEventOrigin: getFilterLeadEventOrigin(orderType)
            }
          }
        : tourTimeSlotId
        ? {
            tourTimeSlotFilter: {
              tourTimeSlotIds: [tourTimeSlotId],
              sellingChannel: getFilterSellingChannel(channel),
              tourTimeSlotOrigin: getLeadTourTimeSlotOrigin(orderType)
            }
          }
        : {}
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      setLeads(data.leads.filter((lead) => lead.data.email)),
    onError: (error) =>
      defaultErrorHandler(error, t('Error while loading leads'))
  })
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  const handleChannelChange = useCallback(
    (e, newChannel: ToggleButtonChannel) =>
      newChannel !== null && setChannel(newChannel),
    []
  )
  const handleOrderTypeChange = useCallback(
    (e, newOrderType: ToggleButtonOrderType) =>
      newOrderType !== null && setOrderType(newOrderType),
    []
  )
  const handleCreateButtonClick = useCallback(() => {
    openCreateMessageDrawer()
    onClose()
  }, [onClose, openCreateMessageDrawer])
  useEffect(() => {
    if (!skip) {
      getLeads()
    }
  }, [getLeads, skip])
  useEffect(
    () => (isLeadsLoading ? setShowBackdrop(true) : setShowBackdrop(false)),
    [isLeadsLoading, setShowBackdrop]
  )
  return (
    <>
      <Dialog
        open={isOpen}
        scroll="paper"
        sx={{[`& .${dialogClasses.paper}`]: {width: 480}}}
        fullWidth
      >
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {t('Create new message')}
        </DialogTitleWithCloseButton>
        <DialogContent
          sx={{display: 'flex', flexDirection: 'column', gap: 3, pt: 3, pb: 3}}
          dividers
        >
          <Typography variant="body1" color="textSecondary">
            {eventId
              ? t(
                  'Filter message recipients from event. Select option “all”, in both filters, if you would like to send message to all customers.'
                )
              : t(
                  'Filter message recipients from tour time slot. Select option “all”, in both filters, if you would like to send message to all customers.'
                )}
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
            <Typography variant="body2" color="textSecondary">
              {t('Filter contacts by channel')}
            </Typography>
            <ToggleButtonGroup
              value={channel}
              exclusive
              onChange={handleChannelChange}
              size="small"
            >
              {Object.values(ToggleButtonChannel).map((ch) => (
                <ToggleButton
                  key={ch}
                  value={ch}
                  sx={{
                    '&.Mui-selected': {
                      color: 'text.primary'
                    }
                  }}
                >
                  {translateChannel(ch)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
            <Typography variant="body2" color="textSecondary">
              {t('Filter contacts by order type')}
            </Typography>
            <ToggleButtonGroup
              value={orderType}
              exclusive
              onChange={handleOrderTypeChange}
              size="small"
            >
              {Object.values(ToggleButtonOrderType).map((ot) => (
                <ToggleButton
                  key={ot}
                  value={ot}
                  sx={{
                    '&.Mui-selected': {
                      color: 'text.primary'
                    }
                  }}
                >
                  {translateOrderType(ot)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Typography variant="body1" color="textSecondary">
            {t('Message will be sent to {{count}} recipients.', {
              count: leads.length
            })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{py: 1.5, px: 3}}>
          <Button
            color="primary"
            variant="contained"
            disabled={leads.length === 0}
            onClick={handleCreateButtonClick}
          >
            {t('Create')}
          </Button>
        </DialogActions>
      </Dialog>
      <CreateMessageDrawer
        isOpen={isCreateMessageDrawerOpen}
        onClose={closeCreateMessageDrawer}
        leads={leads.map((lead) => ({id: lead.id, contact: lead.data.email!}))}
        defaultValues={{
          [MessageFormField.LeadIds]: leads.map((lead) => lead.id)
        }}
      />
    </>
  )
}

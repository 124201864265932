import {Paper, Typography} from '@mui/material'
import React from 'react'

export const formatJsonToPreview = (code: Object) =>
  JSON.stringify(code, null, 4)

interface IJsonPreviewProps {
  stringifiedJson: string
}

export const JsonPreview: React.FC<IJsonPreviewProps> = ({
  stringifiedJson
}: IJsonPreviewProps) => (
  <Paper
    variant="outlined"
    sx={(theme) => ({p: 2, backgroundColor: theme.palette.grey[100]})}
  >
    <Typography
      variant="overline"
      color="textSecondary"
      component="pre"
      sx={{whiteSpace: 'pre-wrap', fontFamily: 'monospace'}}
    >
      {stringifiedJson}
    </Typography>
  </Paper>
)

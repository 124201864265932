import {TypedUseSelectorHook, useSelector as _useSelector} from 'react-redux'
import {applyMiddleware, combineReducers, createStore} from 'redux'
import logger from 'redux-logger'
import {canvasReducer, CanvasState} from './canvas/reducer'
import {displayModeReducer, DisplayModeState} from './displayMode/reducer'
import {editorModeReducer, IEditorModeState} from './editorMode/reducer'
import {modifierKeysReducer, ModifierKeysState} from './keyboardKeys/reducer'
import {pricingReducer} from './pricing/reducer'
import {IPricingState} from './pricing/types'
import {IRefsState, refsReducer} from './refs/reducer'
import {SeatGroups, seatGroupsReducer} from './seatGroups/reducer'
import {ISelectionState, selectionReducer} from './selection/reducer'
import {ISidePanelState, sidePanelReducer} from './sidePanel'
import {IUndoableState, undoableReducer} from './undoable/reducer'

export interface IAppState {
  canvas: CanvasState
  displayMode: DisplayModeState
  editorMode: IEditorModeState
  undoable: IUndoableState
  pricing: IPricingState
  refs: IRefsState
  sidePanel: ISidePanelState
  selection: ISelectionState
  modifierKeys: ModifierKeysState
  seatGroups: SeatGroups
}

const rootReducer = combineReducers({
  canvas: canvasReducer,
  displayMode: displayModeReducer,
  editorMode: editorModeReducer,
  undoable: undoableReducer,
  pricing: pricingReducer,
  refs: refsReducer,
  sidePanel: sidePanelReducer,
  selection: selectionReducer,
  modifierKeys: modifierKeysReducer,
  seatGroups: seatGroupsReducer
})

export const createTmpStore = () =>
  createStore(rootReducer, applyMiddleware(logger))

export const useSelector: TypedUseSelectorHook<IAppState> = _useSelector

import {TreatGroupSeatsOptions} from '../__generated__/schema'

export type {
  ICreateShape,
  ICreateText,
  IRectangle,
  IShape,
  IText
} from '../shared/editor/objectTypes'

export {AuditoriumIcon} from '@attendio/shared-components'

// enums can/must be re-exported, otherwise TS complains
export {SelectionMode} from '../shared/editor/objectTypes'

export enum DrawTool {
  ICON = 'icon',
  SEAT = 'seat',
  SEAT_ROW = 'seat row',
  SEAT_ROWS = 'seat rows',
  SHAPE = 'shape',
  TEXT = 'text',
  ZONE = 'zone'
}

export type SeatGroup = {
  id: string
  name?: string
  seatUuids: Array<string>
  eCommerceTreatOptions: TreatGroupSeatsOptions
  retailTreatOptions: TreatGroupSeatsOptions
}

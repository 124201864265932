import {omit} from 'lodash'
import {SeatGroup} from '../../types'

export const SET_SEAT_GROUPS = 'SET_SEAT_GROUPS'
export const DELETE_SEAT_GROUP = 'DELETE_SEAT_GROUP'
export const EDIT_SEAT_GROUP = 'EDIT_SEAT_GROUP'
export const CREATE_SEAT_GROUP = 'CREATE_SEAT_GROUP'

export type SeatGroups = {
  [id: string]: SeatGroup
}

export interface ISetSeatGroupsAction {
  type: typeof SET_SEAT_GROUPS
  payload: SeatGroups
}

export interface IDeleteSeatGroupAction {
  type: typeof DELETE_SEAT_GROUP
  payload: string
}

export interface IEditSeatGroupAction {
  type: typeof EDIT_SEAT_GROUP
  payload: SeatGroup
}

export interface ICreateSeatGroupAction {
  type: typeof CREATE_SEAT_GROUP
  payload: SeatGroup
}

export type SeatGroupActions =
  | ISetSeatGroupsAction
  | IDeleteSeatGroupAction
  | IEditSeatGroupAction
  | ICreateSeatGroupAction

const initialState: SeatGroups = {}

export const seatGroupsReducer = (
  state = initialState,
  action: SeatGroupActions
): SeatGroups => {
  switch (action.type) {
    case SET_SEAT_GROUPS:
      return action.payload
    case DELETE_SEAT_GROUP:
      return omit(state, action.payload)
    case EDIT_SEAT_GROUP:
    case CREATE_SEAT_GROUP:
      return Object.assign({}, state, {[action.payload.id]: action.payload})
    default:
      return state
  }
}

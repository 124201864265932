import {useCallback, useMemo} from 'react'
import {ShowAgeClassificationCode} from '../../../../../../__generated__/schema'
import {useTranslateAgeClassification} from '../../../../../../hooks/translateAgeClassification'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {getFilteredItems} from '../../../../../common/generalFilter/utils'
import {useShowAgeClassificationCodes} from '../../../graphql'

export const useAgeClassificationCodes = () => {
  const {data} = useShowAgeClassificationCodes()
  const translateAgeClassification = useTranslateAgeClassification()
  const allAgeClassificationCodeItems: IIdListItem<ShowAgeClassificationCode>[] =
    useMemo(
      () =>
        (data?.showAgeClassificationCodes || []).map((code) => ({
          id: code,
          firstRow: translateAgeClassification(code)
        })),
      [data?.showAgeClassificationCodes, translateAgeClassification]
    )
  const getAgeClassificationCodeItems = useCallback(
    (hasText?: string) =>
      Promise.resolve(getFilteredItems(allAgeClassificationCodeItems, hasText)),
    [allAgeClassificationCodeItems]
  )
  const getAgeClassificationCodeItemsByIds = useCallback(
    (codes: ShowAgeClassificationCode[]) =>
      Promise.resolve(
        allAgeClassificationCodeItems.filter(({id}) => codes.includes(id))
      ),
    [allAgeClassificationCodeItems]
  )
  return {
    getAgeClassificationCodeItems,
    getAgeClassificationCodeItemsByIds
  }
}

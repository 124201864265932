import {Button, Drawer, drawerClasses} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useTourParams} from '../../../../../utils/pathname'
import {DrawerSection, DrawerTemplateHeader} from '../../../../common'
import {CreateMessageDialog} from '../../../../common/createMessageDialog'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {useTourTimeSlotForDetailDrawer} from './graphql'
import {TourTimeSlot} from './TourTimeSlot'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      tourTimeSlot: {id: string; label: string}
    } => ({
      tourTimeSlot: {
        id: 'tourTimeSlot',
        label: t('Tour time slot')
      }
    }),
    [t]
  )
}

interface ITourTimeSlotDetailDrawerProps {
  onExited: () => void
}

export const TourTimeSlotDetailDrawer: React.FC<ITourTimeSlotDetailDrawerProps> =
  ({onExited}: ITourTimeSlotDetailDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {tourTimeSlotId} = useTourParams()
    const navigationItems = useNavigationItems()
    const {data, loading, error} = useTourTimeSlotForDetailDrawer(
      tourTimeSlotId,
      isNaN(tourTimeSlotId)
    )
    const {
      state: isDrawerOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const {
      state: isCreateMessageDialogOpen,
      setTrue: openCreateMessageDialog,
      setFalse: closeCreateMessageDialog
    } = useBooleanState(false)
    const [title, setTitle] = useState<string>(t<string>('Tour time slot'))
    const getUserLocaleTranslation = useGetUserLocaleTranslation()
    const hasPermissionToAccessCreateMessageDialog = P([
      PermissionCode.SendMessage,
      PermissionCode.ReadLeads
    ])
    useEffect(() => {
      if (!isNaN(tourTimeSlotId)) {
        openDrawer()
      }
    }, [openDrawer, tourTimeSlotId])
    useEffect(() => {
      if (data) {
        setTitle(getUserLocaleTranslation(data.tourTimeSlot.names))
      }
    }, [data, getUserLocaleTranslation])
    return (
      <>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={closeDrawer}
          SlideProps={{onExited}}
          sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 960, width: '100%'}}}
        >
          <DrawerTemplateWithSideNavigation
            DrawerTemplateProps={{
              isLoading: loading,
              errorMessage: error && t('Error while loading tour time slot'),
              header: (
                <DrawerTemplateHeader
                  title={title}
                  onLeftActionClick={closeDrawer}
                />
              )
            }}
            navigationItems={navigationItems}
          >
            {data?.tourTimeSlot && (
              <DrawerSection
                id={navigationItems.tourTimeSlot.id}
                label={navigationItems.tourTimeSlot.label}
                actions={
                  hasPermissionToAccessCreateMessageDialog && (
                    <Button color="primary" onClick={openCreateMessageDialog}>
                      {t('Send message')}
                    </Button>
                  )
                }
              >
                <TourTimeSlot tourTimeSlot={data.tourTimeSlot} />
              </DrawerSection>
            )}
          </DrawerTemplateWithSideNavigation>
        </Drawer>
        {hasPermissionToAccessCreateMessageDialog && (
          <CreateMessageDialog
            tourTimeSlotId={tourTimeSlotId}
            isOpen={isCreateMessageDialogOpen}
            onClose={closeCreateMessageDialog}
          />
        )}
      </>
    )
  }

import {AxiosRequestConfig} from 'axios'

export const BASE_PORTOS_URL = 'http://127.0.0.1'
export const PORTOS_PORT = 3010

export const getPortosConfig = <C = any>({
  port = PORTOS_PORT,
  ...config
}: AxiosRequestConfig<C> & {
  port?: number
}): AxiosRequestConfig<C> => ({
  baseURL: `${BASE_PORTOS_URL}:${port}/api/v1/`,
  timeout: 30000,
  timeoutErrorMessage: 'Request timed out',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  },
  ...config
})

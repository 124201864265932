import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  TourTimeSlotForDetailDrawerQuery,
  TourTimeSlotForDetailDrawerQueryVariables
} from '../../../../../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT, USER_FIELDS} from '../../graphql'

const TOUR_TIME_SLOT_FOR_DETAIL_DRAWER = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${USER_FIELDS}
  query TourTimeSlotForDetailDrawer($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
      endsAt
      duration
      guide {
        ...UserFields
      }
      versionCode
      ageClassificationCode
      venue {
        id
        name
        address {
          town
          postalCode
        }
      }
      tour {
        id
        division {
          id
          name
        }
      }
      admissionRate {
        id
        name
      }
    }
  }
`

export const useTourTimeSlotForDetailDrawer = (id: number, skip?: boolean) =>
  useQuery<
    TourTimeSlotForDetailDrawerQuery,
    TourTimeSlotForDetailDrawerQueryVariables
  >(TOUR_TIME_SLOT_FOR_DETAIL_DRAWER, {
    variables: {id},
    fetchPolicy: 'network-only',
    skip
  })

import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {TourTimeSlotForDetailDrawerQuery} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {useTranslateAgeClassification} from '../../../../../hooks/translateAgeClassification'
import {useTranslateShowVersion} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../../utils/formatting'
import {ReadOnlyRow} from '../../../../common/ReadOnly'

interface ITourTimeSlotProps {
  tourTimeSlot: TourTimeSlotForDetailDrawerQuery['tourTimeSlot']
}

export const TourTimeSlot: React.FC<ITourTimeSlotProps> = ({
  tourTimeSlot
}: ITourTimeSlotProps) => {
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDate, formatTime} = useDateTimeFormatters()
  const {durationToHoursMinutesLongFormat, durationToMinutesFormat} =
    useShowDurationFormatters()
  const formatUserName = useFormatUserName()
  const translateShowVersion = useTranslateShowVersion()
  const translateAgeClassification = useTranslateAgeClassification()
  const startsAt = new Date(tourTimeSlot.startsAt)
  const endsAt = new Date(tourTimeSlot.endsAt)
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', p: 2}}>
      <ReadOnlyRow label={t('Title')}>
        {getUserLocaleTranslation(tourTimeSlot.names)}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Date')}>{formatDate(startsAt)}</ReadOnlyRow>
      <ReadOnlyRow label={t('Time')}>
        {[
          formatTime(startsAt),
          `(${t('Ends at {{time}}', {
            time: formatTime(endsAt)
          })})`
        ].join(' ')}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Duration')}>
        {[
          durationToHoursMinutesLongFormat(tourTimeSlot.duration),
          `(${durationToMinutesFormat(tourTimeSlot.duration)})`
        ].join(', ')}
      </ReadOnlyRow>
      {tourTimeSlot.guide && (
        <ReadOnlyRow label={t('Guide')}>
          {formatUserName(tourTimeSlot.guide)}
        </ReadOnlyRow>
      )}
      {tourTimeSlot.versionCode && (
        <ReadOnlyRow label={t('Version')}>
          {translateShowVersion(tourTimeSlot.versionCode)}
        </ReadOnlyRow>
      )}
      {tourTimeSlot.ageClassificationCode && (
        <ReadOnlyRow label={t('Age restrictions')}>
          {translateAgeClassification(tourTimeSlot.ageClassificationCode)}
        </ReadOnlyRow>
      )}
      <ReadOnlyRow label={t('Venue')}>
        {[
          tourTimeSlot.venue.name,
          [
            tourTimeSlot.venue.address.town,
            tourTimeSlot.venue.address.postalCode
          ]
            .filter(Boolean)
            .join(' ')
        ]
          .filter(Boolean)
          .join(', ')}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Division')}>
        {tourTimeSlot.tour.division.name}
      </ReadOnlyRow>
      <ReadOnlyRow label={t('Admission rate')}>
        {tourTimeSlot.admissionRate.name}
      </ReadOnlyRow>
    </Box>
  )
}

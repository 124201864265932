import {compact, last, mapValues} from 'lodash'
import React, {Reducer, useCallback, useReducer} from 'react'
import DraggableList from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DivisionState,
  ExistingCountryCode,
  NarrowDivisionPropertiesFragment,
  PermissionCode,
  TemplateAssignmentPropertiesFragment,
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {
  DeviceServices,
  useDeviceServices
} from '../../../../hooks/deviceServices'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {
  CashDrawerController,
  CashDrawerOpenLocation,
  CheckoutPaymentMethodsViewMode,
  ICashDrawerSettings,
  ICustomerDisplaySettings,
  IDirectTicketPrintSettings,
  IPosTerminalSettings,
  IRegistrationOfSalesSettings,
  ShopViewMode,
  TourTimeSlotViewMode,
  ZonePlanView
} from '../../../../types'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  InputBlockWithoutSpacings,
  SingleSideNavigationList
} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {useNotifications} from '../../../context/notifications'
import {
  BottomBarActionsWrapper,
  CenteredLayout,
  CenteredLayoutListWrapper
} from '../Layout'
import {AssignTemplateDialog} from './AssignTemplateDialog'
import {CashDrawer} from './CashDrawer'
import {CustomerDisplaySettings} from './CustomerDisplaySettings'
import {DefaultViewBlock} from './DefaultViewBlock'
import {
  DraggableDeviceServiceItem,
  IDeviceServiceItemCommonProps
} from './DeviceServiceItem'
import {useDeviceSettingsAnchors} from './deviceSettingsAnchors'
import {DirectTicketPrint} from './DirectTicketPrint'
import {EnabledDivisions} from './EnabledDivisions'
import {InputBlockHeadingRow} from './InputBlockHeadingRow'
import {PosTerminal} from './PosTerminal'
import {defaultDeviceSettingsState, deviceSettingsStateReducer} from './reducer'
import {RegistrationOfSales} from './RegistrationOfSales'
import {ShopSettingsBlock} from './ShopSettingsBlock'
import {TemplateTypeBlock} from './TemplateTypeBlock'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState
} from './types'

interface IDeviceSettingsProps {
  clientTemplateAssignments: TemplateAssignmentPropertiesFragment[]
  divisions: NarrowDivisionPropertiesFragment[]
}

const getDefaultTemplateReducerState = ({
  clientTemplates,
  deviceTemplateIds,
  enabledDivisions,
  divisions,
  ...other
}: {
  clientTemplates: TemplatePropertiesFragment[]
  deviceTemplateIds: {
    // noinspection JSUnusedLocalSymbols
    [templateType: string]: number[]
  }
  zonePlanView: ZonePlanView
  shopViewMode: ShopViewMode
  tourTimeSlotViewMode: TourTimeSlotViewMode
  checkoutPaymentMethodsViewMode: CheckoutPaymentMethodsViewMode
  registrationOfSales: IRegistrationOfSalesSettings
  divisionId: number | null
  deviceServices: DeviceServices[]
  divisions: NarrowDivisionPropertiesFragment[]
  enabledDivisions: number[]
  cashDrawer: ICashDrawerSettings
  posTerminal: IPosTerminalSettings
  warehouseId: number | null
  customerDisplay: ICustomerDisplaySettings
  directTicketPrint: IDirectTicketPrintSettings
}): DeviceSettingsState => ({
  ...defaultDeviceSettingsState,
  ...other,
  assignedTemplatesByType: mapValues(
    deviceTemplateIds,
    (templateIds: number[]) =>
      compact(
        templateIds.map((templateId) =>
          clientTemplates.find((ct) => ct.id === templateId)
        )
      )
  ),
  enabledDivisions: compact(
    enabledDivisions.map((ed) => divisions.find(({id}) => id === ed))
  )
})

export const DeviceSettings: React.FC<IDeviceSettingsProps> = ({
  clientTemplateAssignments,
  divisions
}: IDeviceSettingsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()
  const history = useHistory()
  const closePage = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.index())
  }, [history])

  const clientTemplatesGroupedByType = clientTemplateAssignments.reduce<
    {[type in Partial<TemplateType>]: TemplatePropertiesFragment[]}
  >(
    (acc, ta) =>
      ta.template.types.reduce<
        {[type in Partial<TemplateType>]: TemplatePropertiesFragment[]}
      >(
        (innerAcc, type) => ({
          ...innerAcc,
          [type]: [...(innerAcc[type] || []), ta.template]
        }),
        acc
      ),
    {} as {[type in Partial<TemplateType>]: TemplatePropertiesFragment[]}
  )

  const deviceSettingsAnchors = useDeviceSettingsAnchors(
    clientTemplatesGroupedByType
  )
  const [deviceServices, setDeviceServices] = useDeviceServices()

  const [zonePlanView, setZonePlanView] = useLocalStorageState<ZonePlanView>(
    LocalStorageKey.ZonePlanView,
    ZonePlanView.ListView
  )
  const [shopViewMode, setShopViewMode] = useLocalStorageState<ShopViewMode>(
    LocalStorageKey.ShopViewMode,
    ShopViewMode.ListView
  )
  const [tourTimeSlotViewMode, setTourTimeSlotViewMode] =
    useLocalStorageState<TourTimeSlotViewMode>(
      LocalStorageKey.TourTimeSlotViewMode,
      TourTimeSlotViewMode.GridView
    )
  const [checkoutPaymentMethodsViewMode, setCheckoutPaymentMethodsViewMode] =
    useLocalStorageState<CheckoutPaymentMethodsViewMode>(
      LocalStorageKey.CheckoutPaymentMethodsViewMode,
      CheckoutPaymentMethodsViewMode.ExpandedFirstGroup
    )
  const [registrationOfSales, setRegistrationOfSales] =
    useLocalStorageState<IRegistrationOfSalesSettings>(
      LocalStorageKey.RegistrationOfSales,
      {
        enabled: false,
        port: null,
        provider: null,
        cashRegisterCode: null
      }
    )
  const [divisionId, setDivisionId] = useLocalStorageState<number | null>(
    LocalStorageKey.CashDeskShopDivisionId,
    null
  )

  const [deviceTemplateIds, setDeviceTemplateIds] = useLocalStorageState<{
    [templateType: string]: number[]
  }>(LocalStorageKey.TemplateIdsAssignedToDeviceByType, {})

  const [enabledDivisions, setEnabledDivisions] = useLocalStorageState<
    number[]
  >(LocalStorageKey.EnabledDivisions, [])

  const [cashDrawer, setCashDrawer] = useLocalStorageState<ICashDrawerSettings>(
    LocalStorageKey.CashDrawer,
    {
      controller: CashDrawerController.None,
      openLocation: CashDrawerOpenLocation.DontOpen,
      ipAddress: null
    }
  )

  const [posTerminal, setPosTerminal] =
    useLocalStorageState<IPosTerminalSettings>(LocalStorageKey.PosTerminal, {
      enabled: false,
      port: null,
      paymentMethodId: null
    })

  const [warehouseId, setWarehouseId] = useLocalStorageState<number | null>(
    LocalStorageKey.WarehouseId,
    null
  )

  const [customerDisplay, setCustomerDisplay] =
    useLocalStorageState<ICustomerDisplaySettings>(
      LocalStorageKey.CustomerDisplaySettings,
      {
        enabled: false,
        windowManagementEnabled: false,
        screen: null
      }
    )

  const [directTicketPrint, setDirectTicketPrint] =
    useLocalStorageState<IDirectTicketPrintSettings>(
      LocalStorageKey.DirectTicketPrintSettings,
      {
        enabled: false,
        printer: null
      }
    )

  const {addInfoNotification} = useNotifications()

  const [deviceSettingsState, dispatch] = useReducer<
    Reducer<DeviceSettingsState, DeviceSettingsAction>
  >(
    deviceSettingsStateReducer,
    getDefaultTemplateReducerState({
      clientTemplates: clientTemplateAssignments.map((cta) => cta.template),
      deviceTemplateIds,
      zonePlanView,
      shopViewMode,
      tourTimeSlotViewMode,
      checkoutPaymentMethodsViewMode,
      registrationOfSales,
      divisionId,
      deviceServices,
      enabledDivisions,
      divisions,
      cashDrawer,
      posTerminal,
      warehouseId,
      customerDisplay,
      directTicketPrint
    })
  )
  const changeDeviceServices = useCallback((payload: DeviceServices[]) => {
    dispatch({type: DeviceSettingsActionTypes.ChangeDeviceServices, payload})
  }, [])

  const handleAvailabilityChanged = useCallback(
    (link: string) => {
      changeDeviceServices(
        deviceSettingsState.deviceServices.reduce<DeviceServices[]>(
          (acc, item) => [
            ...acc,
            item.link === link
              ? {...item, isAvailable: !item.isAvailable}
              : item
          ],
          []
        )
      )
    },
    [deviceSettingsState.deviceServices, changeDeviceServices]
  )

  const isLastItem = useCallback(
    (link: string) => link === last(deviceSettingsState.deviceServices)?.link,
    [deviceSettingsState.deviceServices]
  )

  const handleSaveButtonClick = useCallback(() => {
    setDeviceServices(deviceSettingsState.deviceServices)
    setDeviceTemplateIds(
      mapValues(deviceSettingsState.assignedTemplatesByType, (templates) =>
        templates.map((t) => t.id)
      )
    )
    setZonePlanView(deviceSettingsState.zonePlanView)
    setShopViewMode(deviceSettingsState.shopViewMode)
    setTourTimeSlotViewMode(deviceSettingsState.tourTimeSlotViewMode)
    setCheckoutPaymentMethodsViewMode(
      deviceSettingsState.checkoutPaymentMethodsViewMode
    )
    setRegistrationOfSales(
      deviceSettingsState.registrationOfSales.enabled &&
        !deviceSettingsState.registrationOfSales.provider
        ? {enabled: false, provider: null, port: null, cashRegisterCode: null}
        : deviceSettingsState.registrationOfSales
    )
    setDivisionId(deviceSettingsState.divisionId)
    setEnabledDivisions(deviceSettingsState.enabledDivisions.map(({id}) => id))
    setCashDrawer(deviceSettingsState.cashDrawer)
    setPosTerminal(deviceSettingsState.posTerminal)
    setWarehouseId(deviceSettingsState.warehouseId)
    setCustomerDisplay(deviceSettingsState.customerDisplay)
    setDirectTicketPrint(
      deviceSettingsState.directTicketPrint.enabled &&
        !deviceSettingsState.directTicketPrint.printer
        ? {
            enabled: false,
            printer: null
          }
        : deviceSettingsState.directTicketPrint
    )
    addInfoNotification(t('Device settings saved'))
    history.replace(routeTo.admin.cashDesk.deviceInformation())
  }, [
    setDeviceServices,
    deviceSettingsState.deviceServices,
    deviceSettingsState.assignedTemplatesByType,
    deviceSettingsState.zonePlanView,
    deviceSettingsState.shopViewMode,
    deviceSettingsState.tourTimeSlotViewMode,
    deviceSettingsState.checkoutPaymentMethodsViewMode,
    deviceSettingsState.registrationOfSales,
    deviceSettingsState.divisionId,
    deviceSettingsState.enabledDivisions,
    deviceSettingsState.cashDrawer,
    deviceSettingsState.posTerminal,
    deviceSettingsState.warehouseId,
    deviceSettingsState.customerDisplay,
    deviceSettingsState.directTicketPrint,
    setDeviceTemplateIds,
    setZonePlanView,
    setShopViewMode,
    setTourTimeSlotViewMode,
    setCheckoutPaymentMethodsViewMode,
    setRegistrationOfSales,
    setDivisionId,
    setEnabledDivisions,
    setCashDrawer,
    setPosTerminal,
    setWarehouseId,
    setCustomerDisplay,
    setDirectTicketPrint,
    addInfoNotification,
    t,
    history
  ])
  return (
    <CenteredLayout
      bottomBar={
        <BottomBarActionsWrapper>
          <CancelButton onClick={closePage} />
          <SaveButton onClick={handleSaveButtonClick} />
        </BottomBarActionsWrapper>
      }
    >
      <SingleSideNavigationList items={deviceSettingsAnchors} />
      <CenteredLayoutListWrapper>
        <InputBlockWithoutSpacings
          header={deviceSettingsAnchors.services.label}
          blockId={deviceSettingsAnchors.services.id}
        >
          <InputBlockHeadingRow
            label={t('Select services available on this device')}
            subLabel={t(
              'You can reorder or change the availability of the services listed below on this device. The first service in the queue will be the default for return after successful checkout of the cart.'
            )}
          />
          <DraggableList<
            DeviceServices,
            IDeviceServiceItemCommonProps,
            DraggableDeviceServiceItem
          >
            list={deviceSettingsState.deviceServices}
            itemKey={'link'}
            template={DraggableDeviceServiceItem}
            onMoveEnd={(list) => {
              changeDeviceServices([...list])
            }}
            constrainDrag
            padding={0}
            commonProps={{
              onAvailabilityChanged: handleAvailabilityChanged,
              isLastItem
            }}
          />
        </InputBlockWithoutSpacings>
        <ShopSettingsBlock
          divisions={divisions}
          selectedDivisionId={deviceSettingsState.divisionId}
          blockId={deviceSettingsAnchors.shopSettings.id}
          blockLabel={deviceSettingsAnchors.shopSettings.label}
          selectedWarehouseId={deviceSettingsState.warehouseId}
          dispatch={dispatch}
        />
        <DefaultViewBlock
          blockId={deviceSettingsAnchors.defaultViews.id}
          blockLabel={deviceSettingsAnchors.defaultViews.label}
          selectedZonePlanView={deviceSettingsState.zonePlanView}
          selectedShopViewMode={deviceSettingsState.shopViewMode}
          selectedTourTimeSlotViewMode={
            deviceSettingsState.tourTimeSlotViewMode
          }
          selectedCheckoutPaymentMethodsViewMode={
            deviceSettingsState.checkoutPaymentMethodsViewMode
          }
          dispatch={dispatch}
        />
        <DirectTicketPrint
          settings={deviceSettingsState}
          dispatch={dispatch}
          blockId={deviceSettingsAnchors.directTicketPrint.id}
          blockLabel={deviceSettingsAnchors.directTicketPrint.label}
        />
        <CashDrawer
          blockId={deviceSettingsAnchors.cashDrawer.id}
          blockLabel={deviceSettingsAnchors.cashDrawer.label}
          settings={deviceSettingsState.cashDrawer}
          dispatch={dispatch}
        />
        <PosTerminal
          blockId={deviceSettingsAnchors.posTerminal.id}
          blockLabel={deviceSettingsAnchors.posTerminal.label}
          settings={deviceSettingsState.posTerminal}
          dispatch={dispatch}
        />
        {effectiveClient?.countryCode === ExistingCountryCode.Sk && (
          <RegistrationOfSales
            blockId={deviceSettingsAnchors.registrationOfSales.id}
            blockLabel={deviceSettingsAnchors.registrationOfSales.label}
            settings={deviceSettingsState.registrationOfSales}
            dispatch={dispatch}
          />
        )}
        {P([PermissionCode.CustomerDisplay]) && (
          <CustomerDisplaySettings
            settings={deviceSettingsState}
            dispatch={dispatch}
            blockId={deviceSettingsAnchors.customerDisplay.id}
            blockLabel={deviceSettingsAnchors.customerDisplay.label}
          />
        )}
        <EnabledDivisions
          blockId={deviceSettingsAnchors.enabledDivisions.id}
          blockLabel={deviceSettingsAnchors.enabledDivisions.label}
          divisions={divisions.filter(
            (division) => division.state === DivisionState.Active
          )}
          enabledDivisions={deviceSettingsState.enabledDivisions}
          dispatch={dispatch}
        />
        {Object.entries(clientTemplatesGroupedByType).map(
          ([templateType, clientTemplatesForType]) => (
            <TemplateTypeBlock
              templateType={templateType as TemplateType}
              clientTemplates={clientTemplatesForType}
              key={templateType}
              anchorLabel={deviceSettingsAnchors[templateType].label}
              anchorId={deviceSettingsAnchors[templateType].id}
              dispatch={dispatch}
              assignedTemplatesByType={
                deviceSettingsState.assignedTemplatesByType
              }
              areDeviceTemplatesAlreadyAssigned={Boolean(
                deviceTemplateIds[templateType]?.length
              )}
            />
          )
        )}
        <AssignTemplateDialog
          templateType={deviceSettingsState.assignDialogOpenedForType}
          templates={deviceSettingsState.availableTemplates}
          onClose={() => {
            dispatch({
              type: DeviceSettingsActionTypes.CloseAssignDialog
            })
          }}
          getOnAssignButtonClickHandler={(
              template: TemplatePropertiesFragment
            ) =>
            () => {
              dispatch({
                type: DeviceSettingsActionTypes.AssignTemplate,
                payload: template
              })
            }}
        />
      </CenteredLayoutListWrapper>
    </CenteredLayout>
  )
}

import {LocaleCode} from './__generated__/schema'

export enum CurrencySignPosition {
  START = 'start',
  END = 'end'
}

export enum ZonePlanView {
  ListView = 'listView',
  SeatingPlan = 'seatingPlan'
}

export enum RegistrationOfSalesProvider {
  Portos = 'portos'
}

export interface IRegistrationOfSalesSettings {
  enabled: boolean
  port: number | null
  provider: RegistrationOfSalesProvider | null
  cashRegisterCode: string | null
}

export enum ShopViewMode {
  ListView = 'listView',
  GridView = 'gridView',
  PriceLookupCodeInput = 'priceLookupCodeInput'
}

export enum TourTimeSlotViewMode {
  GridView = 'gridView',
  ListView = 'listView'
}

export enum CheckoutPaymentMethodsViewMode {
  ExpandedFirstGroup = 'expandedFirstGroup',
  DoNotExpandAnyGroup = 'doNotExpandAnyGroup'
}

export enum CashDrawerController {
  None = 'none',
  VirtuosNetworkController = 'virtuosNetworkController'
}

export enum CashDrawerOpenLocation {
  DontOpen = 'dontOpen',
  BeforeCheckout = 'beforeCheckout',
  AfterCheckout = 'afterCheckout'
}

export interface ICashDrawerSettings {
  openLocation: CashDrawerOpenLocation
  controller: CashDrawerController
  ipAddress: string | null
}

export interface ICustomerDisplayScreen {
  name: string
  top: number
  left: number
}

export interface ICustomerDisplaySettings {
  enabled: boolean
  windowManagementEnabled: boolean
  screen: ICustomerDisplayScreen | null
}

export interface IDirectTicketPrintSettings {
  enabled: boolean
  printer: string | null
}

export interface IPosTerminalSettings {
  enabled: boolean
  port: number | null
  paymentMethodId: number | null
}

export interface PosTerminalApiConfigResponse {
  // eslint-disable-next-line camelcase
  api_port: number
  terminal: {
    // eslint-disable-next-line camelcase
    message_protocol: string
    host: string
    port: number
    id?: string
    // eslint-disable-next-line camelcase
    initial_timeout: number
  }
}

export interface PosTerminalApiConfigSchemaResponse {
  properties: {
    terminal: {
      properties: {
        // eslint-disable-next-line camelcase
        message_protocol: {
          format: string[]
        }
      }
    }
  }
}

export enum PosTerminalCurrency {
  CZK = 203,
  EUR = 978,
  USD = 840,
  GBP = 826,
  RUB = 643
}
export interface PosTerminalApiTerminalRequest {
  returnUrl?: string
  data: {
    amount: number
    language?: LocaleCode
    externalId?: string
    additionalNumericId?: number
    cashbackAmount?: number
    clientId?: number
    currency?: PosTerminalCurrency
  }
}

export interface IPosTerminalApiTerminalRepeatLastMessageRequest {
  returnUrl?: string
}

export enum PosTerminalApiResponseState {
  succeeded = 'succeeded',
  failed = 'failed',
  cancelled = 'cancelled',
  error = 'error'
}
export interface PosTerminalApiTerminalResponse {
  state?: PosTerminalApiResponseState
  authorizationCode: string
  terminalId?: string
  rawPosResponse: {
    authorizationCode?: string
    cardNumber?: string
    responseCode?: string
    transaction?: string
    uniqTransId?: string
    transactionId?: string
    applicationId?: string
  }
}

interface IPortosPhysicalAddress {
  country: string
  streetName: string
  municipality: string | null
  buildingNumber: string | null
  propertyRegistrationNumber: string | null
  deliveryAddress: {
    postalCode: string
  }
}

export interface IPortosIdentityResponse {
  dic: string
  ico: string | null
  icdph: string | null
  corporateBodyFullName: string
  organizationUnit: {
    cashRegisterCode: string
    cashRegisterType: 'Standard' | 'Portable'
    hasRegistrationException: boolean
    organizationUnitName: string | null
    physicalAddress: IPortosPhysicalAddress
  }
  physicalAddress: IPortosPhysicalAddress
}

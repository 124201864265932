import {EventContentArg} from '@fullcalendar/common'
import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import {EventState} from '../../../../../__generated__/schema'

import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {Theme} from '../../../../../theme'
import {addOpacityToHex} from '../../../../../utils/colors'
import {eventStateColors} from '../../../../constants'
import {getDistributionString} from '../common'
import {useGetPriceString} from '../utils'
import {EventContextMenu, useEventContextMenu} from './EventContextMenu'
import {ViewType} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  eventCustom: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    paddingLeft: 6,
    paddingRight: 6,
    background: theme.palette.background.paper
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium
  },
  dot: {
    height: 13,
    width: 13,
    borderRadius: '50%',
    flexShrink: 0
  },
  preventOverflow: {
    overflow: 'hidden',
    '& > p:last-child': {
      paddingBottom: theme.spacing(1.5)
    }
  },
  opacity: {
    '&  p,span': {
      opacity: '50%'
    }
  }
}))

export const EventContentWithHooks: React.FC<EventContentArg> = (
  args: EventContentArg
) => {
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const getPriceString = useGetPriceString()
  const classes = useStyles()
  const {event, timeText, view} = args
  const isDayGridMonth = view.type === ViewType.Month
  const {
    state,
    borderColor,
    textColor,
    division,
    auditorium,
    activePricing,
    formatCode,
    versionCode,
    soundMixCode,
    startsAt
  } = event.extendedProps
  const hasEndBeforeNow = event.end
    ? dayjs(event.end || undefined).isBefore(dayjs())
    : false

  const stateColors = eventStateColors[state as EventState]
  const distributionText =
    formatCode || versionCode || soundMixCode
      ? getDistributionString({
          format: translateShowFormatAbbreviation(formatCode),
          soundMix: translateShowSoundMixAbbreviation(soundMixCode),
          version: translateShowVersionAbbreviation(versionCode)
        })
      : ''
  const {contextMenu, openContextMenu, setContextMenu, canOpenContextMenu} =
    useEventContextMenu()
  return (
    <div
      className={cn(classes.eventCustom, {
        [classes.opacity]: hasEndBeforeNow
      })}
      style={{
        border: `1px solid ${
          hasEndBeforeNow ? addOpacityToHex(borderColor, 0.5) : borderColor
        }`
      }}
      onContextMenu={canOpenContextMenu ? openContextMenu : undefined}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={0.25}
        className={classes.preventOverflow}
      >
        <Typography variant="caption">{timeText}</Typography>
        {stateColors && (
          <div
            className={classes.dot}
            style={{
              backgroundColor: stateColors.color,
              border: `3px solid ${stateColors.background}`
            }}
          />
        )}
      </Box>
      <div className={classes.preventOverflow}>
        {event.title && (
          <Typography
            className={classes.title}
            style={{color: textColor}}
            variant="body2"
          >
            {event.title}
          </Typography>
        )}
        {division?.shortName && (
          <Typography variant="caption" component="p">
            {division?.shortName}
          </Typography>
        )}
        {distributionText && (
          <Typography variant="caption" component="p">
            {distributionText}
          </Typography>
        )}
        {activePricing && (
          <Typography variant="caption" component="p">
            {getPriceString(activePricing)}
          </Typography>
        )}
        {isDayGridMonth && auditorium?.name && (
          <Typography variant="caption" component="p">
            {auditorium?.name}
          </Typography>
        )}
      </div>
      <EventContextMenu
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        event={{
          id: parseInt(event.id, 10),
          title: event.title,
          startsAt: startsAt ? new Date(startsAt) : undefined,
          distributionText,
          divisionName: division?.name
        }}
      />
    </div>
  )
}

/**
 * @description: full calendar throws an error, if you try to use a hook in EventContent on this level, but it's ok in a child
 */
export const EventContent: React.FC<EventContentArg> = (
  args: EventContentArg
) => <EventContentWithHooks {...args} />

import {
  getParsedItemFromStorage,
  LocalStorageKey
} from '../../../../../hooks/storage'
import {IPosTerminalSettings} from '../../../../../types'

export const getPosTerminalAppSettings = () =>
  getParsedItemFromStorage<IPosTerminalSettings>(
    localStorage,
    LocalStorageKey.PosTerminal
  )

export const getPosTerminalAppPort = (): number | null | undefined =>
  getPosTerminalAppSettings()?.port

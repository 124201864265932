import {useMutation, useQuery} from '@apollo/react-hooks'
import {QueryHookOptions} from '@apollo/react-hooks/lib/types'
import {FetchPolicy} from 'apollo-client'
import {DocumentNode} from 'graphql'
import gql from 'graphql-tag'
import _ from 'lodash'
import {useCallback, useMemo} from 'react'

import {
  AuditoriumLayoutPricing,
  AuditoriumLayoutPricingState,
  AuditoriumState,
  CancelEventsMutation,
  CancelEventsMutationVariables,
  CopyEventMutation,
  CopyEventMutationVariables,
  CopyEventsMutation,
  CopyEventsMutationVariables,
  CreateEventMutation,
  CreateEventMutationVariables,
  DeleteEventsMutation,
  DeleteEventsMutationVariables,
  EventDiscountsQuery,
  EventDiscountsQueryVariables,
  EventPropertiesFragment,
  EventQuery,
  EventQueryVariables,
  EventsQuery,
  EventsQueryVariables,
  EventVenuePropertiesFragment,
  EventVenuesQuery,
  EventVenuesQueryVariables,
  MoveEventMutation,
  MoveEventMutationVariables,
  PublishEventsMutation,
  PublishEventsMutationVariables,
  ResizeEventMutation,
  ResizeEventMutationVariables,
  SellingChannel,
  Translated,
  UpdateAllowedDiscountsSellingChannelsForEventMutation,
  UpdateAllowedDiscountsSellingChannelsForEventMutationVariables,
  UpdateGeneralEventDataMutation,
  UpdateGeneralEventDataMutationVariables
} from '../../../../__generated__/schema'
import {
  getGenreBorderColor,
  getGenreTextColor
} from '../../../../hooks/showGenres'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {
  AVAILABLE_DISCOUNT_PROPERTIES_FRAGMENT,
  GET_EVENT_VENUES,
  TRANSLATED_LOCALES_FRAGMENT
} from '../graphql'

import {useGetEventsQueryParams} from './common'

export interface IEvent extends EventPropertiesFragment {
  extendedProps: {
    id: number
    textColor: string
    borderColor: string
  }
  // Properties that fullcalendar library requires
  title: string
  start: Date
  end: Date
  resourceId: string
}

export type SortedVenues = Array<EventVenuePropertiesFragment>

export interface IVenueMap {
  [venueId: number]: EventVenuePropertiesFragment
}

export interface IVenueEventsMap {
  [venueId: number]: Array<IEvent>
}

export interface IEventsScreenData {
  venues: IVenueMap
  sortedVenues: SortedVenues
  events: IVenueEventsMap
}

// TODO: (nice to have, low priority now)
/* Add backwards backend resolvers like
 auditoriumLayoutPricing {
   auditoriumLayout {
     auditorim {
       venue {
         ...
       }
     }
   }
 }
*/
export const EVENT_PROPERTIES_FRAGMENT = gql`
  fragment EventProperties on Event {
    id
    divisionId
    division {
      id
      name
      shortName
    }
    names {
      ...TranslatedLocales
    }
    state
    activePricing {
      id
      minPrice
      maxPrice
    }

    startsAt
    duration
    serviceTime
    endsAt

    showId
    show {
      genreCodes
    }

    auditoriumLayoutPricingId
    auditoriumLayoutPricing {
      id
      ticketTypes {
        price
      }
    }
    venue {
      id
    }
    auditorium {
      id
      name
    }

    formatCode
    soundMixCode
    versionCode
    ageClassificationCode
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const DETAIL_EVENT_PROPERTIES_FRAGMENT = gql`
  fragment DetailEventProperties on Event {
    id
    allowedDiscountsSellingChannels
    division {
      id
      name
    }
    names {
      ...TranslatedLocales
    }
    state

    pricings {
      startsAt
      id
      minPrice
      maxPrice
    }
    activePricing {
      id
      minPrice
      maxPrice
    }

    startsAt
    duration
    serviceTime
    endsAt

    venue {
      id
      name
      address {
        complex
        street
        town
        postalCode
        country
      }
    }
    auditorium {
      id
      name
    }
    auditoriumLayout {
      id
      name
      capacity
    }
    auditoriumLayoutPricing {
      id
      name
    }

    createdAt
    createdBy
    updatedAt
    updatedBy

    moved

    formatCode
    soundMixCode
    versionCode
    ageClassificationCode

    showOnWebsiteAndApi
    gateOpensAt
    gateClosedAt
    checkingOption
    organizerNote
    ticketNote
    show {
      typeCode
    }

    salesAndReservation {
      pointOfSale {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
        reservationExpirationType
        reservationExpirationLastFor
        reservationExpirationExpiresAt
        isEnabledPaymentForReservation
      }
      online {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
        reservationExpirationType
        reservationExpirationLastFor
        reservationExpirationExpiresAt
        isEnabledPaymentForReservation
      }
    }
    ecommerceEventURL
    maxNumberOfOccupiedSeatsOnRetail
    maxNumberOfOccupiedSeatsOnEcommerce
    costCenterId
    marketingLabelId
    eventCategoryId
    businessPartnerId
    feePercentageOfSoldTickets
    feeFixedAmountPerSoldTicket
    feeFixedAmountPerEvent
    feeMinimumGuarantee
    thirdPartyPurchaseURL
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const GET_EVENTS = gql`
  query Events($filter: EventsFilterInput!) {
    events(filter: $filter) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

const GET_EVENT = gql`
  query Event($id: Int!) {
    event(id: $id) {
      ...DetailEventProperties
    }
    costCenters {
      id
      name
      isActive
    }
    marketingLabels {
      id
      name
      isActive
    }
    eventCategories {
      id
      name
      isActive
    }
    businessPartners(
      paginationInput: {offset: 0, limit: 300}
      filter: {categories: [movie_distributor, agency, licensing]}
    ) {
      items {
        id
        companyName
        state
      }
    }
  }
  ${DETAIL_EVENT_PROPERTIES_FRAGMENT}
`
const MOVE_EVENT = gql`
  mutation MoveEvent($id: Int!, $startsAt: DateTime!) {
    moveEvent(id: $id, startsAt: $startsAt) {
      ...DetailEventProperties
    }
  }
  ${DETAIL_EVENT_PROPERTIES_FRAGMENT}
`

const RESIZE_EVENT = gql`
  mutation ResizeEvent($id: Int!, $duration: PositiveInt!) {
    resizeEvent(id: $id, duration: $duration) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

const UPDATE_GENERAL_EVENT_DATA = gql`
  mutation UpdateGeneralEventData($id: Int!, $data: GeneralEventDataInput!) {
    updateGeneralEventData(id: $id, data: $data) {
      ...DetailEventProperties
    }
  }
  ${DETAIL_EVENT_PROPERTIES_FRAGMENT}
`

const COPY_EVENTS = gql`
  mutation CopyEvents($auditoriumId: Int!, $ids: [Int!]!, $daysOffset: Int!) {
    copyEvents(
      auditoriumId: $auditoriumId
      ids: $ids
      daysOffset: $daysOffset
    ) {
      id
    }
  }
`

const DELETE_EVENTS = gql`
  mutation deleteEvents($ids: [Int!]!) {
    deleteEvents(ids: $ids)
  }
`
const PUBLISH_EVENTS = gql`
  mutation publishEvents($ids: [Int!]!) {
    publishEvents(ids: $ids) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`
const CANCEL_EVENTS = gql`
  mutation cancelEvents($ids: [Int!]!) {
    cancelEvents(ids: $ids) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

const useRefetchEventsQuery = (): {
  query: DocumentNode
  variables: EventsQueryVariables
  fetchPolicy: FetchPolicy
} => {
  const {from, to} = useGetEventsQueryParams()
  return {
    query: GET_EVENTS,
    variables: {filter: {from, to}},
    fetchPolicy: 'network-only'
  }
}

export const getEventExtendedProps = (event: EventPropertiesFragment) => ({
  id: event.id,
  borderColor: getGenreBorderColor(event.show.genreCodes[0]),
  textColor: getGenreTextColor(event.show.genreCodes[0]),
  state: event.state
})

const eventsToVenuesMap = (
  events: Array<EventPropertiesFragment>,
  venues: IVenueMap,
  clientTranslation: (field: Translated) => string
): IVenueMap => {
  return Object.values(venues).reduce(
    (res, {id: venueId}: EventVenuePropertiesFragment) => {
      const eventsWithinCurrentVenue = events.filter(
        (event) => event.venue.id === venueId
      )

      const transformedEvents: Array<IEvent> = eventsWithinCurrentVenue.map(
        (event) => ({
          extendedProps: getEventExtendedProps(event),
          start: new Date(event.startsAt),
          title: clientTranslation(event.names),
          end: new Date(event.endsAt),
          resourceId: `${event.auditorium.id}`,
          ...event
        })
      )

      return {
        ...res,
        [venueId]: transformedEvents
      }
    },
    {}
  )
}

const hasArchivedOrActiveLayoutPricing = (auditorium: {
  auditoriumLayouts: Array<{
    auditoriumLayoutPricings: Array<Pick<AuditoriumLayoutPricing, 'status'>>
  }>
}) => {
  const layoutPricings = _.flatten(
    auditorium.auditoriumLayouts.map(
      (auditoriumLayout) => auditoriumLayout.auditoriumLayoutPricings
    )
  )
  return layoutPricings.some(
    (layoutPricing) =>
      layoutPricing.status === AuditoriumLayoutPricingState.Active ||
      // We should be able to see/edit events, for which there is no 'active' layout pricing
      // because it got 'archived' after event(s) creation.
      // We just do not allow to create new event for such auditorium.
      layoutPricing.status === AuditoriumLayoutPricingState.Archived
  )
}

const useGetVenuesInfo = () => {
  const {data, loading, error} =
    useQuery<EventVenuesQuery, EventVenuesQueryVariables>(GET_EVENT_VENUES)

  const transformedData: IVenueMap | null = useMemo(() => {
    if (data && data.venues) {
      const transformed = _.chain(data.venues)
        .keyBy('id')
        .mapValues((v) => {
          const auditoriums = v.auditoriums
            .filter((a) => a.state === AuditoriumState.Active)
            .filter((a) => hasArchivedOrActiveLayoutPricing(a))
            // 'title' property is required by 'fullcalendar' in resources screen
            .map((a) => ({...a, title: a.name}))
          return {
            ...v,
            auditoriums: _.orderBy(auditoriums, (a) => a.order ?? a.id)
          }
        })
        .value()

      // Note: when `pickBy` is used in chain TS complains about strange "__typename" related error,
      // probably some lodash types issue
      return _.pickBy(transformed, (v) => v.auditoriums.length !== 0)
    } else {
      return null
    }
  }, [data])
  return {
    venues: transformedData,
    sortedVenues:
      transformedData &&
      _.orderBy(Object.values(transformedData), (v) => v.order ?? v.id),
    loading,
    error
  }
}

const useGetEvents = () => {
  const {from, to} = useGetEventsQueryParams()

  return useQuery<EventsQuery, EventsQueryVariables>(GET_EVENTS, {
    variables: {filter: {from, to}},
    fetchPolicy: 'network-only'
  })
}

export const useGetEvent = (
  id: number,
  options: QueryHookOptions<EventQuery, EventQueryVariables> = {}
) =>
  useQuery<EventQuery, EventQueryVariables>(GET_EVENT, {
    ...options,
    variables: {id}
  })

export const useUpdateGeneralEventData = () => {
  const [updateGeneralEventData] = useMutation<
    UpdateGeneralEventDataMutation,
    UpdateGeneralEventDataMutationVariables
  >(UPDATE_GENERAL_EVENT_DATA)
  return (variables: UpdateGeneralEventDataMutationVariables) =>
    updateGeneralEventData({variables})
}

export const useGetEventsScreenData = () => {
  const getUserLocaleTranslation = useGetUserLocaleTranslation()

  const {
    data: dataEvents,
    loading: loadingEvents,
    error: errorEvents
  } = useGetEvents()

  const {
    venues,
    sortedVenues,
    loading: loadingVenues,
    error: errorVenues
  } = useGetVenuesInfo()

  const events =
    venues &&
    dataEvents &&
    dataEvents.events &&
    eventsToVenuesMap(dataEvents.events, venues, getUserLocaleTranslation)

  return useMemo(
    () => ({
      data: {
        events: events || null,
        venues,
        sortedVenues
      },
      loading: loadingVenues || loadingEvents,
      error: errorVenues || errorEvents
    }),
    [
      errorEvents,
      errorVenues,
      events,
      loadingEvents,
      loadingVenues,
      sortedVenues,
      venues
    ]
  )
}

export const useMoveEvent = () => {
  const [moveEvent] =
    useMutation<MoveEventMutation, MoveEventMutationVariables>(MOVE_EVENT)
  return (variables: MoveEventMutationVariables) => moveEvent({variables})
}

export const useResizeEvent = () => {
  const [resizeEvent] =
    useMutation<ResizeEventMutation, ResizeEventMutationVariables>(RESIZE_EVENT)
  return useCallback(
    (id, duration) => {
      resizeEvent({variables: {id, duration}})
    },
    [resizeEvent]
  )
}

export const useCopyEvents = () => {
  const eventsQuery = useRefetchEventsQuery()

  const [copyEvents] = useMutation<
    CopyEventsMutation,
    CopyEventsMutationVariables
  >(COPY_EVENTS, {
    refetchQueries: [eventsQuery]
  })
  return useCallback(
    (auditoriumId: number, ids: Array<number>, daysOffset: number) =>
      copyEvents({variables: {auditoriumId, ids, daysOffset}}),
    [copyEvents]
  )
}

export const useDeleteEvents = () => {
  const eventsQuery = useRefetchEventsQuery()
  const [deleteEvents] = useMutation<
    DeleteEventsMutation,
    DeleteEventsMutationVariables
  >(DELETE_EVENTS, {
    refetchQueries: [eventsQuery]
  })
  return useCallback(
    (ids: number[]) =>
      deleteEvents({
        variables: {ids}
      }),
    [deleteEvents]
  )
}

export const usePublishEvents = () => {
  const eventsQuery = useRefetchEventsQuery()
  const [publishEvents] = useMutation<
    PublishEventsMutation,
    PublishEventsMutationVariables
  >(PUBLISH_EVENTS, {
    refetchQueries: [eventsQuery]
  })
  return useCallback(
    (ids: number[]) =>
      publishEvents({
        variables: {
          ids
        }
      }),
    [publishEvents]
  )
}

export const useCancelEvents = () => {
  const eventsQuery = useRefetchEventsQuery()
  const [cancelEvents] = useMutation<
    CancelEventsMutation,
    CancelEventsMutationVariables
  >(CANCEL_EVENTS, {
    refetchQueries: [eventsQuery]
  })
  return useCallback(
    (ids: number[]) =>
      cancelEvents({
        variables: {
          ids
        }
      }),
    [cancelEvents]
  )
}

const CREATE_EVENT = gql`
  mutation CreateEvent($data: CreateEventInput!) {
    createEvent(data: $data) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

export const useCreateEvent = () => {
  const eventsQuery = useRefetchEventsQuery()

  const [createEvent] = useMutation<
    CreateEventMutation,
    CreateEventMutationVariables
  >(CREATE_EVENT, {
    refetchQueries: [eventsQuery]
  })
  return useCallback(
    ({data}: CreateEventMutationVariables) => createEvent({variables: {data}}),
    [createEvent]
  )
}

export const GET_EVENT_DISCOUNTS = gql`
  query eventDiscounts($eventId: Int!) {
    event(id: $eventId) {
      id
      allowedDiscountsSellingChannels
      availableDiscounts {
        ...AvailableDiscountProperties
      }
    }
  }
  ${AVAILABLE_DISCOUNT_PROPERTIES_FRAGMENT}
`

export const useGetEventDiscounts = (eventId: number) =>
  useQuery<EventDiscountsQuery, EventDiscountsQueryVariables>(
    GET_EVENT_DISCOUNTS,
    {
      variables: {
        eventId
      },
      fetchPolicy: 'cache-and-network'
    }
  )

const UPDATE_ALLOWED_DISCOUNTS_SELLING_CHANNELS_FOR_EVENT = gql`
  mutation updateAllowedDiscountsSellingChannelsForEvent(
    $eventId: Int!
    $sellingChannels: [SellingChannel!]!
  ) {
    updateAllowedDiscountsSellingChannelsForEvent(
      id: $eventId
      sellingChannels: $sellingChannels
    ) {
      id
      allowedDiscountsSellingChannels
    }
  }
`
export const useUpdateAllowedDiscountsSellingChannelsForEvent = () => {
  const [updateAllowedDiscountsSellingChannelsForEvent] = useMutation<
    UpdateAllowedDiscountsSellingChannelsForEventMutation,
    UpdateAllowedDiscountsSellingChannelsForEventMutationVariables
  >(UPDATE_ALLOWED_DISCOUNTS_SELLING_CHANNELS_FOR_EVENT)
  return useCallback(
    (eventId: number, sellingChannels: SellingChannel[]) =>
      updateAllowedDiscountsSellingChannelsForEvent({
        variables: {eventId, sellingChannels}
      }),
    [updateAllowedDiscountsSellingChannelsForEvent]
  )
}

export const DEFAULT_DIVISION_FOR_AUDITORIUM = gql`
  query DefaultDivisionForAuditorium($id: Int!) {
    auditorium(id: $id) {
      id
      defaultDivision {
        id
        name
      }
    }
  }
`

export const COPY_EVENT = gql`
  mutation CopyEvent(
    $eventId: PositiveInt!
    $destinationDateTime: DateTime!
    $state: EventState
  ) {
    copyEvent(
      eventId: $eventId
      destinationDateTime: $destinationDateTime
      state: $state
    ) {
      id
    }
  }
`

export const useCopyEvent = () => {
  const eventsQuery = useRefetchEventsQuery()
  const [copyEvent] =
    useMutation<CopyEventMutation, CopyEventMutationVariables>(COPY_EVENT)
  return useCallback(
    (variables: CopyEventMutationVariables) =>
      copyEvent({variables, refetchQueries: [eventsQuery]}),
    [copyEvent, eventsQuery]
  )
}

import {Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {FieldValues} from 'react-hook-form'
import {Theme} from '../../theme'

import {FormSwitchInput, IFormSwitchInputProps} from './FormSwitchInput'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    width: '100%',
    gridTemplateAreas: `
      "primaryLabel formSwitch"
      "secondaryLabel secondaryLabel"
    `,
    gap: theme.spacing(0, 3),
    gridTemplateColumns: '1fr auto'
  },
  primaryLabel: {
    gridArea: 'primaryLabel',
    alignSelf: 'center',
    paddingTop: theme.spacing(0.5)
  },
  secondaryLabel: {
    gridArea: 'secondaryLabel'
  },
  formSwitch: {
    gridArea: 'formSwitch'
  }
}))

interface IFormSwitchWithLabelsProps<
  FormValues extends FieldValues = FieldValues
> extends IFormSwitchInputProps<FormValues> {
  primaryLabel: string
  secondaryLabel: string
  className?: string
  primaryLabelTypographyProps?: TypographyProps
}

export const FormSwitchWithLabels = <
  FormValues extends FieldValues = FieldValues
>({
  primaryLabel,
  secondaryLabel,
  className,
  primaryLabelTypographyProps,
  ...FormSwitchProps
}: IFormSwitchWithLabelsProps<FormValues>) => {
  const classes = useStyles()
  return (
    <div className={cn(className, classes.grid)}>
      <Typography
        variant="body1"
        className={classes.primaryLabel}
        {...primaryLabelTypographyProps}
      >
        {primaryLabel}
      </Typography>
      <Typography
        variant="caption"
        className={classes.secondaryLabel}
        color="textSecondary"
      >
        {secondaryLabel}
      </Typography>
      <div className={classes.formSwitch}>
        <FormSwitchInput<FormValues> {...FormSwitchProps} />
      </div>
    </div>
  )
}

import gql from 'graphql-tag'

export const GET_LEADS = gql`
  query leads($filter: LeadsFilterInput!) {
    leads(filter: $filter) {
      id
      data {
        name
        email
      }
    }
  }
`

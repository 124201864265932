import {Box, Divider, MenuItem} from '@mui/material'
import axios from 'axios'
import {isNull} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {
  IPortosIdentityResponse,
  IRegistrationOfSalesSettings,
  RegistrationOfSalesProvider
} from '../../../../types'
import {getPortosConfig} from '../../../../utils/portos'
import {InputBlockWithoutSpacings} from '../../../common'
import {useNotifications} from '../../../context/notifications'
import {Loading} from '../../../visual'
import {RowWithSelect} from './RowWithSelect'
import {RowWithSwitch} from './RowWithSwitch'
import {RowWithTextField} from './RowWithTextField'
import {DeviceSettingsAction, DeviceSettingsActionTypes} from './types'

interface IRegistrationOfSalesProps {
  blockId: string
  blockLabel: string
  settings: IRegistrationOfSalesSettings
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const RegistrationOfSales: React.FC<IRegistrationOfSalesProps> = ({
  blockId,
  blockLabel,
  settings,
  dispatch
}: IRegistrationOfSalesProps) => {
  const {t} = useTranslation()
  const {enabled, provider, port, cashRegisterCode} = settings
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const [portError, setPortError] = useState<boolean>(false)
  const [cashRegisterCodeState, setCashRegisterCodeState] = useState<{
    availableCodes: string[]
    isLoading: boolean
    hasError: boolean
  }>({
    availableCodes: [],
    isLoading: false,
    hasError: false
  })
  const getAvailableCashRegisterCodes = useCallback(async () => {
    if (!isNull(port)) {
      try {
        setCashRegisterCodeState((prevState) => ({
          ...prevState,
          isLoading: true
        }))
        const {data: identitiesData} = await axios.request<
          IPortosIdentityResponse[]
        >(
          getPortosConfig({
            port,
            method: 'get',
            url: '/identities'
          })
        )
        setCashRegisterCodeState((prevState) => ({
          ...prevState,
          availableCodes: identitiesData.map(
            (identity) => identity.organizationUnit.cashRegisterCode
          ),
          hasError: false
        }))
      } catch (error) {
        setCashRegisterCodeState((prevState) => ({
          ...prevState,
          hasError: true,
          availableCodes: []
        }))
        addErrorWithCustomDialogNotification({
          title: t('Communication with Portos failed'),
          contentText: t(
            'There was an error while connecting to Portos. Please check port and make sure that app is running.'
          ),
          confirmButtonLabel: t('Got it')
        })
      } finally {
        setCashRegisterCodeState((prevState) => ({
          ...prevState,
          isLoading: false
        }))
      }
    }
  }, [addErrorWithCustomDialogNotification, port, t])
  return (
    <InputBlockWithoutSpacings
      blockId={blockId}
      header={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {blockLabel}
        </Box>
      }
      headerSx={{width: '100%'}}
    >
      <RowWithSwitch
        primaryLabel={t('Enable registration of sales?')}
        switchProps={{
          checked: enabled,
          onChange: () =>
            dispatch({
              type: DeviceSettingsActionTypes.ChangeRegistrationOfSaleSettings,
              payload: {
                ...settings,
                enabled: !enabled
              }
            })
        }}
      />
      {enabled && (
        <>
          <Divider />
          <RowWithSelect
            helperText={t('*Required')}
            primaryLabel={t(
              'App for communication with local financial authority.'
            )}
            inputLabel={t('Select app')}
            selectProps={{
              required: true,
              label: t('Select app'),
              value: provider || '',
              children: Object.values(RegistrationOfSalesProvider).map(
                (provider) => (
                  <MenuItem key={provider} value={provider}>
                    {provider}
                  </MenuItem>
                )
              ),
              onChange: (e) =>
                dispatch({
                  type: DeviceSettingsActionTypes.ChangeRegistrationOfSaleSettings,
                  payload: {
                    ...settings,
                    provider: e.target.value as RegistrationOfSalesProvider
                  }
                })
            }}
          />
          {provider === RegistrationOfSalesProvider.Portos && (
            <>
              <Divider />
              <RowWithTextField
                primaryLabel={t('Communication port')}
                secondaryLabel={t(
                  'Set up communication port with Portos app. Default is 3010.'
                )}
                textFieldProps={{
                  helperText: portError
                    ? t('*An integer between 0 and 65535 is required.')
                    : t('*Required'),
                  error: portError,
                  defaultValue: isNull(port) ? '' : port,
                  required: true,
                  onChange: (event) => {
                    if (
                      validator.isInt(event.target.value, {
                        min: 0,
                        max: 65535
                      })
                    ) {
                      if (portError) {
                        setPortError(false)
                      }
                      dispatch({
                        type: DeviceSettingsActionTypes.ChangeRegistrationOfSaleSettings,
                        payload: {
                          ...settings,
                          port: parseInt(event.target.value, 10)
                        }
                      })
                    } else {
                      setPortError(true)
                    }
                  }
                }}
              />
              <Divider />
              <RowWithSelect
                helperText={t('*Required')}
                primaryLabel={t('Cash register code')}
                selectProps={{
                  value: cashRegisterCode || '',
                  children: cashRegisterCodeState.isLoading ? (
                    <Loading />
                  ) : cashRegisterCodeState.hasError ? (
                    <MenuItem key={0} value="none" disabled>
                      {t('There was an error while connecting to Portos')}
                    </MenuItem>
                  ) : cashRegisterCodeState.availableCodes.length > 0 ? (
                    cashRegisterCodeState.availableCodes.map((code) => (
                      <MenuItem key={code} value={code}>
                        {code}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value="none" disabled>
                      {t('No cash register codes found')}
                    </MenuItem>
                  ),
                  onOpen: getAvailableCashRegisterCodes,
                  disabled: isNull(port) || portError,
                  required: true,
                  onChange: (event) =>
                    dispatch({
                      type: DeviceSettingsActionTypes.ChangeRegistrationOfSaleSettings,
                      payload: {
                        ...settings,
                        cashRegisterCode: event.target.value as string
                      }
                    })
                }}
              />
            </>
          )}
        </>
      )}
    </InputBlockWithoutSpacings>
  )
}

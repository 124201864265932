import {merge, omit} from 'lodash'
import {DefaultSeatStates} from '../../../../../../__generated__/schema'
import {IObjectsState} from '../../../../../../editor/redux/objects/types'
import {isSeatStateObject} from '../../../../../../editor/SidePanel/utils'
import {SeatGroup} from '../../../../../../editor/types'
import {ObjectsStateValue} from '../../../../../../shared/editor/stateTypes'

export const mergeDefaultSeatStatesIntoAuditoriumLayout = (
  layout: IObjectsState,
  defaultSeatStates?: DefaultSeatStates
) => {
  if (!defaultSeatStates) {
    return layout
  }
  const transformedDefaultSeatStates = Object.entries<
    'disabled' | 'hidden' | 'available'
  >(defaultSeatStates.seats).reduce(
    (
      acc: {
        [uuid: string]: {
          defaultState?: 'disabled' | 'hidden' | 'available'
        }
      },
      [uuid, state]: [uuid: string, state: 'disabled' | 'hidden' | 'available']
    ) => {
      acc[uuid] = {
        defaultState: state
      }
      return acc
    },
    {}
  )
  return merge({}, layout, transformedDefaultSeatStates)
}

export const mergeSeatGroupsIntoAuditoriumLayout = (
  layout: IObjectsState,
  seatGroups: {
    [seatId: string]: SeatGroup
  },
  backgroundColor = '#ff0000'
) => {
  const seatGroupsArr = Object.values(seatGroups)
  if (seatGroupsArr.length === 0) {
    return layout
  }

  const transformedSeatGroups = seatGroupsArr.reduce(
    (
      acc: {
        [id: string]: {
          badge: {
            text: string
            backgroundColor?: string
          }
        }
      },
      seatGroup
    ) => {
      const o = {badge: {text: seatGroup.id, backgroundColor}}
      for (const seatUuid of seatGroup.seatUuids) {
        acc[seatUuid] = o
      }
      return acc
    },
    {}
  )

  return merge({}, layout, transformedSeatGroups)
}

export const splitDefaultStatesAndAuditoriumLayout = (
  layout: IObjectsState
): {
  layout: IObjectsState
  defaultSeatStates: DefaultSeatStates
} =>
  Object.entries<ObjectsStateValue>(layout).reduce(
    (
      acc: {layout: IObjectsState; defaultSeatStates: DefaultSeatStates},
      [key, o]
    ) => {
      acc.layout[key] = isSeatStateObject(o) ? omit(o, 'defaultState') : o
      if (isSeatStateObject(o) && o.defaultState) {
        acc.defaultSeatStates.seats[key] = o.defaultState
      }
      return acc
    },
    {
      layout,
      defaultSeatStates: {
        seats: {},
        disabledCountsInZones: {},
        hiddenCountsInZones: {}
      }
    }
  )

export const removeBadgesFromLayout = (layout: IObjectsState): IObjectsState =>
  Object.entries<ObjectsStateValue>(layout).reduce(
    (acc: IObjectsState, [id, o]) => {
      acc[id] = isSeatStateObject(o) ? omit(o, 'badge') : o
      return acc
    },
    {}
  )

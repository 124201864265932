import {useTranslation} from 'react-i18next'
import {
  ExistingCountryCode,
  PermissionCode,
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {useTranslateTemplateType} from '../../../../hooks/translateTemplates'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'

export const useDeviceSettingsAnchors = (
  templatesGroupedByType: {
    [type in Partial<TemplateType>]: TemplatePropertiesFragment[]
  }
): {
  [key: string]: {
    id: string
    label: string
  }
} => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()
  const translateTemplateType = useTranslateTemplateType()
  const anchorsByTemplateTypes = Object.entries(templatesGroupedByType)
    .filter(([, templates]) => templates.length > 0)
    .reduce(
      (acc, [type]) => ({
        ...acc,
        [type]: {
          id: type,
          label: translateTemplateType(type as TemplateType)
        }
      }),
      {}
    )
  return {
    services: {
      id: 'services',
      label: t('Services')
    },
    shopSettings: {
      id: 'shopSettings',
      label: t('Shop settings')
    },
    defaultViews: {
      id: 'defaultViews',
      label: t('Default views')
    },
    directTicketPrint: {
      id: 'directTicketPrint',
      label: t('Direct ticket print')
    },
    cashDrawer: {
      id: 'cashDrawer',
      label: t('Cash drawer')
    },
    posTerminal: {
      id: 'posTerminal',
      label: t('POS Terminal')
    },
    ...(effectiveClient?.countryCode === ExistingCountryCode.Sk
      ? {
          registrationOfSales: {
            id: 'registrationOfSales',
            label: t('Registration of sales')
          }
        }
      : {}),
    ...(P([PermissionCode.CustomerDisplay])
      ? {
          customerDisplay: {
            id: 'customerDisplay',
            label: t('Customer display')
          }
        }
      : {}),
    enabledDivisions: {
      id: 'enabledDivisions',
      label: t('Enabled divisions')
    },
    ...anchorsByTemplateTypes
  }
}

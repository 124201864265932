import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  ActivateAuditoriumLayoutMutation,
  ArchiveAuditoriumLayoutMutation,
  AuditoriumLayoutQuery,
  AuditoriumLayoutQueryVariables,
  AuditoriumLayoutsQuery,
  AuditoriumLayoutsQueryVariables,
  CopyAuditoriumLayoutMutation,
  CreateAuditoriumLayoutMutation,
  DeleteAuditoriumLayoutMutation,
  MutationActivateAuditoriumLayoutArgs,
  MutationArchiveAuditoriumLayoutArgs,
  MutationCopyAuditoriumLayoutArgs,
  MutationCreateAuditoriumLayoutArgs,
  MutationDeleteAuditoriumLayoutArgs,
  MutationUpdateAuditoriumLayoutArgs,
  UpdateAuditoriumLayoutMutation
} from '../../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../../utils/apollo'
import {eventVenuesQuery} from '../../graphql'
import {GET_AUDITORIUMS} from '../auditoriums/graphql'

const AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT = gql`
  fragment AuditoriumLayoutProperties on AuditoriumLayout {
    id
    name
    capacity
    type
    status
    layout
    seatGroups
    defaultSeatStates {
      seats
      disabledCountsInZones
      hiddenCountsInZones
    }
    auditorium {
      name
      venue {
        name
      }
    }
  }
`

export const GET_AUDITORIUM_LAYOUTS = gql`
  query AuditoriumLayouts($auditoriumId: Int!) {
    auditoriumLayouts(auditoriumId: $auditoriumId) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const GET_AUDITORIUM_LAYOUT = gql`
  query AuditoriumLayout($id: Int!) {
    auditoriumLayout(id: $id) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const CREATE_AUDITORIUM_LAYOUT = gql`
  mutation CreateAuditoriumLayout(
    $auditoriumId: Int!
    $type: AuditoriumLayoutType!
    $data: AuditoriumLayoutInput!
  ) {
    createAuditoriumLayout(
      auditoriumId: $auditoriumId
      type: $type
      data: $data
    ) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const UPDATE_AUDITORIUM_LAYOUT = gql`
  mutation UpdateAuditoriumLayout(
    $id: Int!
    $data: AuditoriumLayoutInput!
    $seatGroups: JSON
  ) {
    updateAuditoriumLayout(id: $id, data: $data, seatGroups: $seatGroups) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const DELETE_AUDITORIUM_LAYOUT = gql`
  mutation DeleteAuditoriumLayout($id: Int!) {
    deleteAuditoriumLayout(id: $id) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const ACTIVATE_AUDITORIUM_LAYOUT = gql`
  mutation ActivateAuditoriumLayout($id: Int!) {
    activateAuditoriumLayout(id: $id) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const ARCHIVE_AUDITORIUM_LAYOUT = gql`
  mutation ArchiveAuditoriumLayout($id: Int!) {
    archiveAuditoriumLayout(id: $id) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

const COPY_AUDITORIUM_LAYOUT = gql`
  mutation CopyAuditoriumLayout(
    $auditoriumId: Int!
    $id: Int!
    $name: String!
  ) {
    copyAuditoriumLayout(auditoriumId: $auditoriumId, id: $id, name: $name) {
      ...AuditoriumLayoutProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PROPERTIES_FRAGMENT}
`

export const useGetAuditoriumLayouts = (auditoriumId: number) => {
  return useQuery<AuditoriumLayoutsQuery, AuditoriumLayoutsQueryVariables>(
    GET_AUDITORIUM_LAYOUTS,
    {variables: {auditoriumId}}
  )
}

export const useGetAuditoriumLayout = (id: number) => {
  return useQuery<AuditoriumLayoutQuery, AuditoriumLayoutQueryVariables>(
    GET_AUDITORIUM_LAYOUT,
    {variables: {id}}
  )
}

export const useCreateAuditoriumLayout = (
  venueId: number,
  auditoriumId: number
) => {
  const [createAuditoriumLayout] = useMutation<
    CreateAuditoriumLayoutMutation,
    MutationCreateAuditoriumLayoutArgs
  >(CREATE_AUDITORIUM_LAYOUT, {
    refetchQueries: () => {
      // Note: we need to re-fetch GET_AUDITORIUMS to detect whether we can / can not
      // delete auditorium (when first auditoriumLayout is created we can not)
      return [
        {query: GET_AUDITORIUM_LAYOUTS, variables: {auditoriumId}},
        {query: GET_AUDITORIUMS, variables: {venueId}}
      ]
    }
  })
  return useCallback(
    ({auditoriumId, type, data}: MutationCreateAuditoriumLayoutArgs) =>
      createAuditoriumLayout({variables: {auditoriumId, type, data}}),
    [createAuditoriumLayout]
  )
}

export const useUpdateAuditoriumLayout = () => {
  const [updateAuditoriumLayout] = useMutation<
    UpdateAuditoriumLayoutMutation,
    MutationUpdateAuditoriumLayoutArgs
  >(UPDATE_AUDITORIUM_LAYOUT)
  return useCallback(
    ({id, data, seatGroups}: MutationUpdateAuditoriumLayoutArgs) =>
      updateAuditoriumLayout({variables: {id, data, seatGroups}}),
    [updateAuditoriumLayout]
  )
}

export const useDeleteAuditoriumLayout = (auditoriumId: number) => {
  const [deleteAuditoriumLayout] = useMutation<
    DeleteAuditoriumLayoutMutation,
    MutationDeleteAuditoriumLayoutArgs
  >(DELETE_AUDITORIUM_LAYOUT, {
    refetchQueries: () => [
      {query: GET_AUDITORIUM_LAYOUTS, variables: {auditoriumId}},
      eventVenuesQuery
    ],
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.deleteAuditoriumLayout)
      }
    }
  })
  return useCallback(
    ({id}: MutationDeleteAuditoriumLayoutArgs) =>
      deleteAuditoriumLayout({variables: {id}}),
    [deleteAuditoriumLayout]
  )
}

export const useActivateAuditoriumLayout = () => {
  const [activateAuditoriumLayout] = useMutation<
    ActivateAuditoriumLayoutMutation,
    MutationActivateAuditoriumLayoutArgs
  >(ACTIVATE_AUDITORIUM_LAYOUT, {
    refetchQueries: () => [eventVenuesQuery]
  })
  return useCallback(
    ({id}: MutationActivateAuditoriumLayoutArgs) =>
      activateAuditoriumLayout({variables: {id}}),
    [activateAuditoriumLayout]
  )
}

export const useArchiveAuditoriumLayout = () => {
  const [archiveAuditoriumLayout] = useMutation<
    ArchiveAuditoriumLayoutMutation,
    MutationArchiveAuditoriumLayoutArgs
  >(ARCHIVE_AUDITORIUM_LAYOUT, {
    refetchQueries: () => [eventVenuesQuery]
  })
  return useCallback(
    ({id}: MutationArchiveAuditoriumLayoutArgs) =>
      archiveAuditoriumLayout({variables: {id}}),
    [archiveAuditoriumLayout]
  )
}

export const useCopyAuditoriumLayout = (auditoriumId: number) => {
  const [copyAuditoriumLayout] = useMutation<
    CopyAuditoriumLayoutMutation,
    MutationCopyAuditoriumLayoutArgs
  >(COPY_AUDITORIUM_LAYOUT, {
    refetchQueries: () => {
      return [{query: GET_AUDITORIUM_LAYOUTS, variables: {auditoriumId}}]
    }
  })
  return useCallback(
    ({auditoriumId, id, name}: MutationCopyAuditoriumLayoutArgs) =>
      copyAuditoriumLayout({variables: {auditoriumId, id, name}}),
    [copyAuditoriumLayout]
  )
}
